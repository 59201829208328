// src/pages/Reports.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';


const Reports = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const navigate = useNavigate();

  const clients = ['Cliente A', 'Cliente B', 'Cliente C'];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleCreateReport = () => {
    console.log(`Criando relatório: ${reportTitle} para ${selectedClient}`);
    setIsModalOpen(false);
    navigate('/reports-view');
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <Header />
        <div className="p-6 text-center animate-fade-left">
          <h1 className="mt-20 text-2xl font-bold mb-4">Relatórios de Auditoria</h1>
          <p className="mb-6">Conteúdo dos Relatórios.</p>
          <div className="flex flex-wrap justify-center gap-4">
            <button
              className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
              onClick={() => navigate('/create-relatory')}
            >
              Criar Novo Relatório
            </button>
            <a href="/reports-consult">
              <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                Consultar Relatório
              </button>
            </a>
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded shadow-lg w-1/3">
                <h2 className="text-xl font-bold mb-4">Criar Novo Relatório</h2>
                <div className="mb-4">
                  <label className="block text-left">Título do Relatório:</label>
                  <input
                    type="text"
                    value={reportTitle}
                    onChange={(e) => setReportTitle(e.target.value)}
                    className="border border-gray-300 px-4 py-2 rounded w-full"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-left">Selecione o Cliente:</label>
                  <select
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    className="border border-gray-300 px-4 py-2 rounded w-full"
                  >
                    <option value="">Selecione um cliente</option>
                    {clients.map((client) => (
                      <option key={client} value={client}>
                        {client}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    className="border border-gray-300 text-red-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
                    onClick={toggleModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
                    onClick={handleCreateReport}
                  >
                    Prosseguir
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Reports;
