import React from 'react';
import { Link } from 'react-router-dom';


const Home = () => {
  const onChange = (perfil: string) => {
    localStorage.setItem("profile", perfil);
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen animate-fade-left">
      <h1 className="text-4xl font-bold mb-8">Sistema de Gestão</h1>
      <div className="flex flex-col items-center mb-8">
        <Link to="/login" onClick={() => onChange('auditor')} className="bg-green-700 hover:bg-green-800 text-white font-bold py-3 px-6 rounded mb-2 w-full">Auditor (a)</Link>
        <Link to="/login" onClick={() => onChange('cliente')} className="bg-green-700 hover:bg-green-800 text-white font-bold py-3 px-6 rounded mb-2 w-full text-center">Cliente</Link>
        <Link to="/login" onClick={() => onChange('empresa')} className="bg-green-700 hover:bg-green-800 text-white font-bold py-3 px-6 rounded mb-2 w-full text-center">Empresa</Link>
        <Link to="/login" onClick={() => onChange('outros')} className="bg-green-700 hover:bg-green-800 text-white font-bold py-3 px-6 rounded mb-2 w-full">Equipe AMZ</Link>
      </div>
      <footer className="text-center text-gray-500 text-sm">© NPCA</footer>
    </div>
  );
};

export default Home;
