import React, { useEffect, useState } from 'react';
import { returnReports } from '../api/reports_client';
import { registerUserLogs } from '../api/userLogs';

const ReportsConsult = () => {
  const [reports, setReports] = useState<any>([]);

  const handleViewProfile = (id: number) => {
    window.location.href = `/relatory/${id}`
  };

  useEffect(() => {
    const fetchData = async () => {
        const reportssRequest = await returnReports()
            
        setReports(reportssRequest);
        if (reports !== null || reports !== '') {
          registerUserLogs("Visualizou lista de relatórios.");
        } 
    };

    fetchData();
  }, []);

  return (
    <div className="mt-20 p-4 md:p-8 animate-fade-left">
      <h1 className="text-2xl font-bold mb-4 text-center">Consultar Relatórios</h1>
      <p className="mb-6 text-center">Lista de Relatórios cadastrados no sistema.</p>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300">Título</th>
              <th className="py-2 px-4 border-b border-gray-300">Ações</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report: {id: number, title: string, name: string, date: string, status: boolean}) => (
              <tr key={report.id} className="text-center">
                <td className="py-2 px-4 border-b border-gray-300">{report.title}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button 
                    className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProfile(report.id)}
                  >
                    Visualizar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportsConsult;
