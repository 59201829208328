import React, { useState, useEffect } from 'react';

type MessageRequestProps = {
    activeProps: boolean,
    title: string,
    message: string
}

export default function MessageRequest({activeProps, title, message}: MessageRequestProps) {
    const [active, setActive] = useState(activeProps);

    useEffect(() => {
        setActive(activeProps);
    }, [activeProps]);

    const handleOk = () => {
        setActive(false);
    }

    return (
        <div>
            {active ? (
                <div className='flex absolute w-full h-full justify-center items-center break-words animate-fade-up'>
                    <div className='fixed bg-white w-80 flex flex-col p-3 shadow-lg z-30' style={{"boxShadow": "0 0 40px #919090;"}}>
                        <h4 className='font-bold'>{title}</h4>
                        <p className='h-full max-w-full break-words'>{message}</p>
                        <div className='flex justify-center'>
                            <button className='button-message' onClick={handleOk}>ok</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
}