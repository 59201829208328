import React, {useEffect, useState, useRef } from "react";
import './registerSpreadsheets.css';
import './spreadsheet.css';
import { filterProjects, filterSpreadsheets, registerSpreadsheet, editSpreadsheet, filesSpreadsheet, removeFileSpreadsheet } from "../../api/spreadsheets";
import { indicatorsFiltredCacheById } from "../../api/indicators";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/navbar";
import Loading from "../../components/Loading/loading";
import MessageRequest from "../../components/CardMessage/MessageRequest/messageRequest";
import { categorieFiltred } from "../../api/categorieFiltred";
import { indicatorsFiltred } from "../../api/indicators";
import Pagination from "../../components/Pagination/pagination";
import { documentations } from "../../api/documentations";
import { services } from "../../api/services";
import axios from "axios";
import { registerUserLogs } from "../../api/userLogs";
import Drawer from '@mui/material/Drawer';
import FileUploadArea from "../../components/FileUploadArea/fileUploadArea";
import ColProfile from "../../components/ColProfile/colProfile";

export default function RegisterSpreadsheets() {
    const { id } = useParams();
    const [spreadsheetId, setSpreadsheetId] = useState<number>(0);
    const [project, setProject] = useState<any>([]);
    const [update, setUpdate] = useState<boolean>(false);
    const [updated, setUpdated] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>([]);
    const [nameStage, setNameStage] = useState<any>([]);
    const [selectedStage, setSelectedStage] = useState<number>(1);
    const [stage, setStage] = useState<string>('');
    const [requirement, setRequirement] = useState<string>('');
    const [indicator, setIndicator] = useState<string>('');
    const [indicatorId, setIndicatorId] = useState<number>(0);
    const [cod, setCod] = useState<string>('');
    const [activeLoading, setActiveLoading] = useState(true);
    const [evidences, setEvidences] = useState<string>('');
    const [action, setAction] = useState<string>('');
    const [cost, setCost] = useState<number>(1);
    const [time, setTime] = useState<number>(1);
    const [difficulty, setDifficulty] = useState<number>(1);
    const [enviromentRisk, setEnviromentRisk] = useState<number>(1);
    const [workerRisk, setWorkerRisk] = useState<number>(1);
    const [communityRisk, setCommunityRisk] = useState<number>(1);
    const [legalRisk, setLegalRisk] = useState<number>(1);
    const [observation, setObservation] = useState<string>('');
    const [criticalDegree, setCriticalDegree] = useState<number>(1);
    const [result, setresult] = useState<string>('');
    const [inputService, setInputService] = useState<number>(1);
    const [errorEvidences, setErrorEvidences] = useState<string | null>(null);
    const [errorAction, setErrorAction] = useState<string | null>(null);
    const [errorCost, setErrorCost] = useState<string | null>(null);
    const [errorTime, setErrorTime] = useState<string | null>(null);
    const [errorDifficulty, setErrorDifficulty] = useState<string | null>(null);
    const [errorEnviromentRisk, setErrorEnviromentRisk] = useState<string | null>(null);
    const [errorWorkerRisk, setErrorWorkerRisk] = useState<string | null>(null);
    const [errorCommunityRisk, setErrorCommunityRisk] = useState<string | null>(null);
    const [errorLegalRisk, setErrorLegalRisk] = useState<string | null>(null);
    const [errorObservation, setErrorObservation] = useState<string | null>(null);
    const [pageActual, setPageActual] = useState<number>(0);
    const [pageFinal, setPageFinal] = useState<number>(0);
    const [valueButton, setValueButton] = useState<number>(0);
    const [indexListPages, setIndexListPages] = useState<number>(0);
    const listIndex: any = []
    const [nameFile, setNameFile] = useState<string>('')
    const [linkFile, setLinkFile] = useState<string>('')
    const iframeRef: any = useRef(null);
    const [typeFile, setTypeFile] = useState<string>('')
    const [fileUpload, setFileUpload] = useState<any>()
    const [dataServices, setDataServices] = useState<any>([]);
    const [dataDocumentations, setDocumentations] = useState<any>([]);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [spreadsheetsRequestFiltred, setSpreadsheetsRequestFiltred] = useState<any>([]);
    const [filesSaved, setFileSaved] = useState([]);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const loadPDF = (linkFile: string) => {
        if (iframeRef.current) {
            iframeRef.current.src = linkFile;
        }
    };

    const handleFileSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map(option => JSON.parse(option.value));
        const detailsLink = String(selectedOptions[0].name).split('.')

        setTypeFile(detailsLink[1])
        setLinkFile(selectedOptions[0].link)
        setFileUpload(selectedOptions[0]);

        if (detailsLink[1] === 'pdf') {
            loadPDF(selectedOptions[0].link)
        }
    };

    const handleRemoveFile = async (id: number) => {
        const removeFileRequest: any = await removeFileSpreadsheet(id);
        
        if(removeFileRequest.status === 204) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso');
            setMessageRequest('Anexo removido com sucesso.');
            registerUserLogs("Removeu arquivo.");
            setUpdated(true);
        }
    }

    const handleFileSelectDocument = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);
    };

    const handleSubmitImage = async () => {
        try {
            const responseDoc: any = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/upload-file/', 
                {
                    user_id: project.stakeholder,
                    spreadsheet_id: spreadsheetsRequestFiltred[pageActual].id,
                    images: fileUpload,
                    file_uploaded: true,
                },
                {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("access_token")
                    }
                })

            setUpdated(true);

            if (update) {
                setActiveLoading(false);
            } 
            
            if (responseDoc.status === 201) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Salvo!');
                setMessageRequest('Anexo salvo com sucesso.');
                registerUserLogs("Anexou arquivo.");
                setTimeout(function() {
                    setUpdated(false);
                }, 5000);
            } 
        } catch (error) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        }
    } 

    const handleSubmitFile = async () => {
        try {
            const formData = new FormData();
            formData.append('user_id', project.stakeholder);
            formData.append('spreadsheet_id', spreadsheetsRequestFiltred[pageActual].id);
            formData.append('file_uploaded', String(false));

            if (fileUpload !== null) {
                formData.append('images', fileUpload);
            }
            const responseDoc: any = await axios.post(process.env.REACT_APP_URL_API_AMZ+'api/spreadsheet/upload-file/', 
                formData,
                {
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("access_token")
                    }
                })

            setUpdated(true);

            if (update) {
                setActiveLoading(false);
            } 
            
            if (responseDoc.status === 201) {
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Salvo!');
                setMessageRequest('Anexo salvo com sucesso.');
                registerUserLogs("Anexou arquivo.");
                setTimeout(function() {
                    setUpdated(false);
                }, 5000);
            } 
        } catch (error) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        }
    } 

    const handleEvidences = (event: any) => {
        setEvidences(event.target.value);
        setErrorEvidences(null);
    }

    const handleAction = (event: any) => {
        setAction(event.target.value);
        setErrorAction(null);
    }

    const handleCost = (event: any) => {
        setCost(event.target.value);
        setErrorCost(null);
    }

    const handleTime = (event: any) => {
        setTime(event.target.value);
        setErrorTime(null);
    }
    const handleDifficulty = (event: any) => {
        setDifficulty(event.target.value);
        setErrorDifficulty(null);
    }
    const handleEnviromentRisk = (event: any) => {
        setEnviromentRisk(event.target.value);
        setErrorEnviromentRisk(null);
    }
    const handleWorkerRisk = (event: any) => {
        setWorkerRisk(event.target.value);
        setErrorWorkerRisk(null);
    }
    const handleCommunityRisk = (event: any) => {
        setCommunityRisk(event.target.value);
        setErrorCommunityRisk(null);
    }
    const handleLegalRisk = (event: any) => {
        setLegalRisk(event.target.value);
        setErrorLegalRisk(null);
    }

    const handleObservation = (event: any) => {
        setObservation(event.target.value);
        setErrorObservation(null);
    }

    const handlePage = (index: number) => {
        setPageActual(index);
        setValueButton(index);
    }

    const handlePrevPage = () => {
        setIndexListPages(indexListPages-1)
    }

    const handleNextPage = () => {
        setIndexListPages(indexListPages+1)
    }

    const handleDefineIdStage = (event: any) => {
        const value = event.target.getAttribute('data-value');
        setSelectedStage(value)
        setPageActual(0);
    }

    useEffect(() => {
        window.localStorage.setItem('stageId', String(selectedStage));
    }, [selectedStage])

    const handleInputService = (event: any) => {
        setInputService(event.target.value);
    }

    const idParams = id ? parseInt(id) : 0;
    
    useEffect(() => {
        const fetchData = async () => {
            const projectRequest = await filterProjects(idParams);
            setProject(projectRequest);        
            const categoriesRequest = await categorieFiltred(projectRequest.categories);
            setCategories(categoriesRequest)
            setNameStage(projectRequest.stages)
            const spreadsheetsRequest = await filterSpreadsheets(projectRequest.id, selectedStage);
            if (spreadsheetsRequest.length === 0) {
                const indicatorsRequest = await indicatorsFiltred(projectRequest.categories[selectedStage], true);
                for (var indicator of indicatorsRequest) {
                    await registerSpreadsheet(projectRequest.id, indicator.id, indicator.stage_id);
                }
                window.location.reload();
            } else {
                setActiveLoading(true)
                setSpreadsheetsRequestFiltred(spreadsheetsRequest);
            }
        }

        setTypeFile('png');
        setLinkFile('');
        setFileUpload([]);

        fetchData();
    }, [updated, selectedStage])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const indicatorData = await indicatorsFiltredCacheById(spreadsheetsRequestFiltred[pageActual].indicators_id);
                setPageFinal(spreadsheetsRequestFiltred.length > 0 ? spreadsheetsRequestFiltred.length - 1 : 0);
                setStage(indicatorData[0].stage_id_stage)
                setSpreadsheetId(spreadsheetsRequestFiltred.length > 0 ? spreadsheetsRequestFiltred[pageActual].id : '')
                setIndicatorId(indicatorData[0].id)
                setRequirement(indicatorData[0].requirements)
                setIndicator(indicatorData[0].indicators)
                setCod(indicatorData[0].cod)
                setEvidences(spreadsheetsRequestFiltred.length > 0 ? (spreadsheetsRequestFiltred[pageActual].evidence === null ? '' : spreadsheetsRequestFiltred[pageActual].evidence) : '')
                setAction(spreadsheetsRequestFiltred.length > 0 ? (spreadsheetsRequestFiltred[pageActual].action === null ? '' : spreadsheetsRequestFiltred[pageActual].action) : '')
                setInputService(spreadsheetsRequestFiltred[pageActual].service)
                setCost(spreadsheetsRequestFiltred[pageActual].cost)
                setTime(spreadsheetsRequestFiltred[pageActual].time)
                setDifficulty(spreadsheetsRequestFiltred[pageActual].difficulty)
                setEnviromentRisk(spreadsheetsRequestFiltred[pageActual].enviroment_risk)
                setWorkerRisk(spreadsheetsRequestFiltred[pageActual].worker_risk)
                setCommunityRisk(spreadsheetsRequestFiltred[pageActual].community_risk)
                setLegalRisk(spreadsheetsRequestFiltred[pageActual].legal_risk)
                setCriticalDegree(spreadsheetsRequestFiltred.length > 0 ? spreadsheetsRequestFiltred[pageActual].critical_degree : '')
                setresult(spreadsheetsRequestFiltred.length > 0 ? spreadsheetsRequestFiltred[pageActual].result : '')
                setObservation(spreadsheetsRequestFiltred.length > 0 ? (spreadsheetsRequestFiltred[pageActual].observation === null ? '' : spreadsheetsRequestFiltred[pageActual].observation) : '')
                
                const filesUploaded = await filesSpreadsheet(project.stakeholder, spreadsheetsRequestFiltred[pageActual].id);
                setFileSaved(filesUploaded);

                const servicesRequest = await services();
                setDataServices(servicesRequest)

                const responseDocumentations = await documentations(project.stakeholder, indicatorData[0].stage_id);
                setDocumentations(responseDocumentations);
                
                if (nameStage != null || nameStage.length > 0) {
                    setActiveLoading(false)
                }

                setFileUpload(null)
                setNameFile('');
            } catch (error) {
                console.error('Erro ao buscar projeto:', error);
            }

            setUpdate(true);
        };

        fetchData();
    }, [pageActual, spreadsheetsRequestFiltred, selectedStage]);

    useEffect(() => {
        setUpdate(false);
    }, [pageActual]);

    let indexPage;

    if (pageFinal > 0) {
        for (indexPage = 0; indexPage < (pageFinal+1); indexPage += 2) {
            listIndex.push([indexPage, indexPage+1])
        }
    }

    const handleBack = () => {
        window.location.href = '/home-page'
    }

    const handleSubmitSpreadsheet = async () => {
        setActiveLoading(true)
        const edit: any = await editSpreadsheet(spreadsheetId, idParams, indicatorId, evidences, String(inputService), action, String(cost), String(time), String(difficulty), String(enviromentRisk), String(workerRisk), String(communityRisk), String(legalRisk), observation);
        setUpdated(true);

        if (update) {
            setActiveLoading(false);
        } 

        if (edit.status === 200) {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Salvo!');
            setMessageRequest('Registro salvo com sucesso.');
            registerUserLogs("Realizando auditoria.");
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        } else {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
            setTimeout(function() {
                setUpdated(false);
            }, 5000);
        }
    }

    const classNameEvidences = errorEvidences ? 'textarea-report-spreadsheet-error' : 'textarea-spreadsheet-report';
    const classNameAction = errorAction ? 'textarea-report-spreadsheet-error' : 'textarea-spreadsheet-report';
    const classObservation = errorObservation ? 'textarea-report-spreadsheet-error' : 'textarea-spreadsheet-report';
    const classNameCost = errorCost ? 'input-create-spreadsheet-error border rounded px-2' : 'input-create-spreadsheet border rounded px-2';
    const classNameTime = errorTime ? 'input-create-spreadsheet-error border rounded px-2' : 'input-create-spreadsheet  border rounded px-2';
    const classNameDifficulty = errorDifficulty ? 'input-create-spreadsheet-error border rounded px-2' : 'input-create-spreadsheet border rounded px-2';
    const classNameEnviromentRisk = errorEnviromentRisk ? 'input-create-spreadsheet-error' : 'input-create-spreadsheet border rounded px-2';
    const classNameWorkerRisk = errorWorkerRisk ? 'input-create-spreadsheet-error border rounded px-2' : 'input-create-spreadsheet border rounded px-2';
    const classNameCommunityRisk = errorCommunityRisk ? 'input-create-spreadsheet-error' : 'input-create-spreadsheet border rounded px-2';
    const classNameLegalRisk = errorLegalRisk ? 'input-create-spreadsheet-error border rounded px-2' : 'input-create-spreadsheet border rounded px-2';
    const [activeEdit, setActiveEdit] = useState<boolean>(false);

    return (
        <div className="register-spreadsheet">
            <Navbar title="Auditor (a)" user={true} buttonSave={true} functionBack={handleBack} functionSave={handleSubmitSpreadsheet} menu={toggleDrawer(true)} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)} sx={{display: "flex !important", alignItems: "flex-start !important", height: "100%!important", width: "250px", overflowY: "hidden!important"}}>
                <div className="sidebar">
                    <div className="menu_stages">
                        {
                            nameStage.map((data: any, index: number) => (
                                <a className={data.stage === stage ? 'selected' : ''} data-value={data.id} onClick={handleDefineIdStage}>{data.stage}</a>
                            ))
                        }
                    </div>
                    <ColProfile idProject={idParams || 0} finish={true} approved={project.approved} idStakeholder={project.stakeholder} />
                </div>
            </Drawer>
            
            <div className='container-sidebar-register'>
                <div className="sidebar">
                    <div className="menu_stages">
                        {
                            nameStage.map((data: any, index: number) => (
                                <a className={data.stage === stage ? 'selected' : ''} data-value={data.id} onClick={handleDefineIdStage}>{data.stage}</a>
                            ))
                        }
                    </div>
                    <ColProfile idProject={idParams || 0} finish={true} approved={project.approved} idStakeholder={project.stakeholder} />
                </div>
            </div>
            <section className='flex flex-col p-10 w-full lg:pl-[300px] animate-fade-left' style={{paddingTop: '100px'}}>
                <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                <Loading activeProps={activeLoading}/>
                <div className='col-title-image-stages flex flex-row items-end mt-3'>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image mr-1"
                        style={{width: '35px', height: '40px'}}
                    />
                    <h2 className="font-bold text-[22px]">{project.name}</h2>
                </div>
                <div className="flex mt-8 lg:flex-row md:flex-row flex-col gap-5 w-full justify-between">
                    <div className="flex flex-col w-full md:w-3/6">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Requisito:</label>
                            <p>
                                {requirement}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:w-3/6">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Indicador:</label>
                            <p className="text-justify">
                                {indicator}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex mt-8 lg:flex-row md:flex-row flex-col gap-5 w-full justify-between">
                    <div className="flex flex-col w-full md:w-3/6">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Etapa:</label>
                            <p>
                                {stage}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col w-full md:w-3/6">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>COD:</label>
                            <p>
                                {cod}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col gap-5 w-full justify-between mt-5">
                    <div className="w-full md:w-3/6 flex flex-col">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Evidência Apresentada:</label>
                        </div>
                        <textarea 
                            id='message' 
                            onChange={handleEvidences} 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={evidences} 
                            cols={50} 
                            rows={8}
                            style={{"resize": "none"}}
                        />
                        <span>{errorEvidences}</span>
                    </div>
                    <div className="w-full md:w-3/6 flex flex-col">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Ação corretiva/Sugestão de melhoria:</label>
                        </div>
                        <textarea 
                            id='message' 
                            onChange={handleAction} 
                            value={action} 
                            cols={50} 
                            rows={8} 
                            style={{"resize": "none"}}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <span>{errorAction}</span>
                    </div>
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col gap-5">
                    <div className="flex gap-2 w-full md:w-3/6">
                        <div className='flex flex-row' style={{height: '30px'}}>
                            <label className='label-spreadsheet'>Anexos:</label>
                            <select className="select-service" style={{width: '75%'}} onChange={handleFileSelect}>
                                {dataDocumentations.map((document: any, index: number) => (
                                    <option key={index} value={JSON.stringify(document)}>
                                        {document.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {linkFile !== '' && (<div className="flex flex-col gap-5 w-full md:w-3/6">
                        <div className='flex flex-row justify-center items-center'>
                            {linkFile && (typeFile !== 'pdf') ? <img className="img-spreadsheet w-full" src={linkFile} alt="anexo" /> : <iframe title="PDF Viewer" ref={iframeRef} width="400px" height="600px"></iframe>}
                        </div>
                        <div className="flex justify-center">
                            {linkFile && (<button className="flex justify-center bg-black text-white p-3 rounded w-full w-3/6" onClick={handleSubmitImage}>salvar anexo</button>)}
                        </div>
                    </div>)}
                </div>
                <div className="flex lg:flex-row md:flex-row flex-col mt-5">
                    <div className="w-full md:w-3/6">
                        <div className='row-label' style={{ display: 'flex', marginBottom: 0 }}>
                            <label className='label-spreadsheet'>Upload de Anexo:</label>
                        </div>
                        <FileUploadArea onFileSelect={handleFileSelectDocument} nameFile={nameFile} />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className='button_add--spreadsheet p-2 rounded font-bold' onClick={handleSubmitFile}>
                                salvar
                            </button>
                        </div>
                    </div>
                </div>
                {filesSaved !== null && (
                    <div className="flex lg:flex-row md:flex-row flex-col mt-5">
                        <div>
                            <div className='row-label'>
                                <label className='label-spreadsheet'>Anexados:</label>
                            </div>
                            <div className="area-documents">
                                {filesSaved && Array.isArray(filesSaved) && filesSaved.map((f: any, index: number) => (
                                    <div className="p-1">
                                        <hr className="divider-spreadsheet1 mb-2" style={{ 'width': '100%', marginLeft: 0 }} />
                                        <a style={{ textDecoration: 'none', height: 'auto' }} href={f.link} target="_blank" rel="noreferrer">
                                            <div className="flex flex-row gap-10">
                                                <div className="flex flex-row gap-4">
                                                    <p style={{width: '30px'}}>{index+1}-</p> 
                                                    <p style={{maxWidth: '90%', height: 'auto', overflow: 'hidden'}}>{f.name}</p>
                                                </div>
                                                <button onClick={() => handleRemoveFile(f.id)}>
                                                    <img src="/remove.svg" alt="remove" className="w-[14px]" />
                                                </button>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                                <hr className="divider-spreadsheet1" style={{ 'width': '100%', marginLeft: 0 }} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="flex flex-row mt-5">
                    <div className="row-spreadsheet">
                        <div className='row-label'>
                            <label className='label-spreadsheet'>Atendimento:</label>
                            <select className="select-service border ml-2" value={inputService} onChange={handleInputService}>
                                {dataServices.map((service: any) => (
                                    <option key={service.scale} value={service.scale}>
                                        {service.service}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {inputService !== 1 ? (
                    <div className="flex flex-col gap-5 lg:w-3/6">
                        <div className="flex flex-col">
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Custo:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleCost} className={classNameCost} value={cost}/>
                            </div>
                            <span>{errorCost}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Tempo:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleTime} className={classNameTime} value={time}/>
                            </div>
                            <span>{errorTime}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Dificuldade:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleDifficulty} className={classNameDifficulty} value={difficulty}/>
                            </div>
                            <span>{errorDifficulty}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Risco Ambiental:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleEnviromentRisk} className={classNameEnviromentRisk} value={enviromentRisk}/>
                            </div>
                            <span>{errorEnviromentRisk}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Risco para o Trabalhador:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleWorkerRisk} className={classNameWorkerRisk} value={workerRisk}/>
                            </div>
                            <span>{errorWorkerRisk}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Risco a Comunidade:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleCommunityRisk} className={classNameCommunityRisk} value={communityRisk}/>
                            </div>
                            <span>{errorCommunityRisk}</span>
                            <div className='row-label' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                                <label className='label-create-spreadsheet'>Risco Legal:</label>
                                <input type="number" style={{ width: '60px', fontWeight: 700 }} onChange={handleLegalRisk} className={classNameLegalRisk} value={legalRisk}/>
                            </div>
                            <span>{errorLegalRisk}</span>
                        </div>
                        <div className="flex flex-col">
                            <div className='flex flex-row' style={{ display: 'flex', marginBottom: 0 }}>
                                <label className='label-spreadsheet' style={{ marginRight: '10px' }}>Grau crítico:</label>
                                <div className="critical-degree">
                                    {criticalDegree}
                                </div>
                            </div>
                            <div className='flex flex-row mt-5' style={{ display: 'flex', marginBottom: 0 }}>
                                <label className='label-spreadsheet' style={{ marginRight: '10px' }}>Resultado:</label>
                                <div className="result">
                                    {result}
                                </div>
                            </div>
                            <div className="mt-5">
                                <div className='flex flex-row'>
                                    <label className='label-spreadsheet'>Observações:</label>
                                </div>
                                <textarea 
                                    id='message' 
                                    onChange={handleObservation} 
                                    style={{"resize": "none"}}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    value={observation} 
                                    cols={50} 
                                    rows={8}
                                />
                                <span>{errorObservation}</span>
                            </div>
                        </div>
                    </div>
                    ) : (
                    <div>
                        <div className="row-spreadsheet">
                            <div className="flex flex-col">
                                <div className='row-label' style={{ display: 'flex', marginBottom: 0 }}>
                                    <label className='label-spreadsheet' style={{ marginRight: '10px', width: '100px' }}>Grau crítico:</label>
                                    <div className="critical-degree">
                                        {criticalDegree}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col mt-5">
                                <div className='row-label' style={{ display: 'flex', marginBottom: 0, marginLeft: '105px' }}>
                                    <label className='label-spreadsheet' style={{ marginRight: '10px' }}>Resultado:</label>
                                    <div className="result">
                                        {result}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className='row-label'>
                                <label className='label-spreadsheet'>Observações:</label>
                            </div>
                            <textarea 
                                id='message' 
                                onChange={handleObservation} 
                                style={{"resize": "none"}}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={observation} 
                                cols={50} rows={8} 
                            />
                            <span>{errorObservation}</span>
                        </div>
                    </div>
                )}
                <div className="flex flex-row justify-center mt-10" style={pageFinal > 1 ? {display: "flex"} : {display: "none"}}>
                    {pageFinal > 0 && (
                        <Pagination handlePrevPage={handlePrevPage} indexListPages={indexListPages} handleNextPage={handleNextPage} maxIndex={listIndex.length-1} valueButton={valueButton} valueButton1={listIndex[indexListPages][0]} functionButton1={() => handlePage(listIndex[indexListPages][0])} valueButton2={listIndex[indexListPages][1]} functionButton2={() => handlePage(listIndex[indexListPages][1])} pageFinal={pageFinal} functionButtonFinal={() => handlePage(pageFinal)}/>
                    )}
                </div>
            </section>
        </div>
    )
}