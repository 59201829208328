import React, { useState } from "react";

const Form: React.FC = () => {
  const [formData, setFormData] = useState({
    title: "",
    coverPhoto: "",
    technicalResponsible: "",
    summary: "",
    presentation: "",
    clientInfo: "",
    auditResponsibles: "",
    criteria: "",
    finalConsiderations: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
    // Aqui você pode adicionar a lógica para salvar os dados
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md animate-fade-left">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Formulário de Projeto</h1>
      <form onSubmit={handleSubmit}>
        {/* Título */}
        <div className="mb-4">
          <label htmlFor="title" className="block text-sm font-medium text-gray-700">Título</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Foto da capa */}
        <div className="mb-4">
          <label htmlFor="coverPhoto" className="block text-sm font-medium text-gray-700">Foto da Capa (URL)</label>
          <input
            type="url"
            id="coverPhoto"
            name="coverPhoto"
            value={formData.coverPhoto}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Responsável técnico */}
        <div className="mb-4">
          <label htmlFor="technicalResponsible" className="block text-sm font-medium text-gray-700">Responsável Técnico</label>
          <input
            type="text"
            id="technicalResponsible"
            name="technicalResponsible"
            value={formData.technicalResponsible}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Sumário */}
        <div className="mb-4">
          <label htmlFor="summary" className="block text-sm font-medium text-gray-700">Sumário</label>
          <textarea
            id="summary"
            name="summary"
            value={formData.summary}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Apresentação */}
        <div className="mb-4">
          <label htmlFor="presentation" className="block text-sm font-medium text-gray-700">Apresentação</label>
          <textarea
            id="presentation"
            name="presentation"
            value={formData.presentation}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Informações do cliente */}
        <div className="mb-4">
          <label htmlFor="clientInfo" className="block text-sm font-medium text-gray-700">Informações do Cliente/Empresa</label>
          <textarea
            id="clientInfo"
            name="clientInfo"
            value={formData.clientInfo}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Auditoria e Responsáveis */}
        <div className="mb-4">
          <label htmlFor="auditResponsibles" className="block text-sm font-medium text-gray-700">Auditoria e Responsáveis</label>
          <textarea
            id="auditResponsibles"
            name="auditResponsibles"
            value={formData.auditResponsibles}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Critérios */}
        <div className="mb-4">
          <label htmlFor="criteria" className="block text-sm font-medium text-gray-700">Critérios</label>
          <textarea
            id="criteria"
            name="criteria"
            value={formData.criteria}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Considerações finais */}
        <div className="mb-6">
          <label htmlFor="finalConsiderations" className="block text-sm font-medium text-gray-700">Considerações Finais</label>
          <textarea
            id="finalConsiderations"
            name="finalConsiderations"
            value={formData.finalConsiderations}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={3}
          />
        </div>

        {/* Botões */}
        <div className="flex justify-end space-x-4">
          <button
            type="reset"
            className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            onClick={() => setFormData({
              title: "",
              coverPhoto: "",
              technicalResponsible: "",
              summary: "",
              presentation: "",
              clientInfo: "",
              auditResponsibles: "",
              criteria: "",
              finalConsiderations: "",
            })}
          >
            Limpar
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
