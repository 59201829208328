import axios from 'axios';

interface Indicator {
    id: number;
    indicators_id_requirements: string;
    indicators_id_indicators: string;
    evidence: string;
    service: string;
    observation: string;
    action: string;
    result: string;
}

interface Attachment {
    id: number;
    name: string;
    link: string;
}

export async function generatePdfRelatory(titleRelatory: string, image: string, companyName: string, stage: string, stageDetails: string, ediction1: string, ediction2: string, ediction3: string, review1: string, review2: string, review3: string, coordination1: string, coordination2: string, coordination3: string, presentationCompany: string, addressCompany: string, cepCompany: string, cityUfCountryCompany: string, president: string, techlead: string, objective: string, methodology: string, initDate: string, endDate: string, month: string, year: string, initNote: string, endNote: string, imageStage: string, indicators: Array<Indicator>, considerations: string, attachments: Array<Attachment>, qrcode: string, accomplished: Array<string>, term: Array<string>) {
    try {
        const pdfRequest = await axios.post(process.env.REACT_APP_URL_API_GERADOR_PDF+'api/v1/relatory',
            {
                titleRelatory,
                image,
                companyName,
                stage,
                stageDetails,
                ediction1,
                ediction2,
                ediction3,
                review1,
                review2,
                review3,
                coordination1,
                coordination2,
                coordination3,
                presentationCompany,
                addressCompany,
                cepCompany,
                cityUfCountryCompany,
                president,
                techlead,
                objective,
                methodology,
                initDate,
                endDate,
                month,
                year,
                initNote,
                endNote,
                imageStage,
                indicators,
                considerations,
                attachments,
                qrcode,
                accomplished,
                term
            },
            {
                responseType: 'blob', 
            });
        if (pdfRequest.status === 201) {
            return pdfRequest.data
        }       
    } catch (error) {
        return []
    }
}