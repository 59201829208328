export const detailsStages = [
    {
        id: 1,
        name: 'AREA LEGAL',
        details: 'A Etapa Área Legal certificará a propriedade, ou o prédio, ou a sala comercial, etc. que possuam a regularização fundiária reconhecida (CDRU - Concessão do Direito Real de Uso ou o título de propriedade), bem como a legalização de todas as atividades exercidas sobre a terra, ou sobre o local fixo, do CNPJ que está sendo alvo do processo de certificação.'
    },
    {
        id: 2,
        name: 'COMUNIDADE ATIVA',
        details: 'A etapa Comunidade Ativa avalia a relação que a empresa estabelece com a comunidade e povos tradicionais que estejam presentes na área ou na região do empreendimento. Sendo totalmente vedado à empresa a realização de atividades que representem ameaça à sobrevivência e aos direitos dessas comunidades, e atribuindo a ela esta etapa quando oferecer ações de responsabilidade social, ambiental, e cultural com a valorização do saber tradiconal aliado ao saber científico, valorizando a cultura local, nas etapas de geração de emprego e renda e fixação de mão de obra local.'
    },
    {
        id: 3,
        name: 'EMPREENDEDOR ATIVO',
        details: 'A etapa Empreendedor Ativo certificará o âmbito da empresas que, em sua constituição legal e na condução de suas práticas e políticas internas, tenham bem definidas as questões tributárias e outras ações em dia, que promovam a capacitação de seus funcionários, que proporcionem infraestrutura adequada para seus colabores e o transporte seguro de produtos e pessoas. Esta etapa incentivará a profissionalização empresarial e o bom gerenciamento dos recursos por meio de programas trabalhistas, ambientais, culturais, reconhecerá as empresas que atuem oferecendo mais do que a legislação pede, evidenciando a conscientização quanto ao cenário de evolução do mercado bussinnes na Amazônia. '
    },
    {
        id: 4,
        name: 'EMPRESA ECONSCIENTE',
        details: 'A etapa Empresa Econsciente certificará os empreendimentos que promovam atividades que fortaleçam, pratiquem e realizem ações de sustentabilidade, transformando suas atividades empresariais na Amazônia em um instrumento de transformação ambiental e conscientização das boas ações de sustentabilidade. O pensamento ambiental e ecológico na região onde estiverem inseridos e o desenvolvimento local. Por exemplo: promovendo além do que a legislação pede, sendo um pioneiro em adotar ações de sustentabilidade, inovação tecnológica, adotando energia limpa, indo além dos incentivos à coleta seletiva, aplicando programas de reutilização, reciclagem e reuso de seus resíduos; monitoramento da qualidade da água nas áreas de influencia direta, indireta e afetada, garantindo que suas atividades não estejam infrigindo o direito comum da sociedade, do solo, se preocupando em adotar programas de análises contínuas para evitar índices de poluição e contaminação do solo e do ar dentro da área da empresa; relação com Stakeholders, geração de emprego e renda na comunidade local, fixação da mão de obra regional, ações sócio ambientais e econômicas com comunidades locais, tradicionais, indígenas, ribeirinhas e quilombolas, quando se aplicar.'
    },
    {
        id: 5,
        name: 'GOVERNANÇA ESG + INOVAÇÃO',
        details: 'A etapa Governança Corporativa concentra-se nos aspectos relacionados à eficiência e eficácia da administração, bem como no enfrentamento da corrupção, promovendo valores como transparência, equidade, prestação de contas e responsabilidade corporativa. Atuando na Amazônia com responsabilidade atribuída às ações éticas, de governança e de compliance.'
    },
    {
        id: 6,
        name: 'PRESTADOR DE SERVIÇOS SUSTENTÁVEL',
        details: 'A etapa Produtor Sustentável certificará os empreendimentos que comprovarem o uso de matéria-prima de origem sustentável ou a realização de práticas de bom manejo em sua produção. Além disso, para cumprir com esta etapa é necessário que o requerente obtenha primeiramente o subselo Terra Legal, e que tenha apoio técnico especializado e comprovado. Na etapa Produtor Sustentável, existirão categorias que contemplarão diferentes cadeias produtivas:Manejo Florestal Sustentável; Silvicultura Sustentável; Produção Animal Sustentável; Produção Agrícola Sustentável; Produção Agrícola Familiar Sustentável; Produto Orgânico Sustentável; Prestador de Serviços Sustentável; e Cadeias Produtivas Sustentáveis.'
    }
]