import React, { useEffect, useState } from 'react';
import { profile } from '../api/profile';
import { registerUserLogs } from '../api/userLogs';
import UsersProfile from './UsersProfile';

interface User {
  id: number,
  name: string,
  email: string,
  cpf: string,
  office: string,
  company: string,
  cnpj: string,
  address_cep_address: string,
  endereco2: string,
  cep_address: string,
  cep_cep_address: string,
  num_cep_address: number,
  complement: string,
  neighborhood_cep_address: string,
  city_cep_address: string,
  uf_cep_address: string,
  country_cep_address: string,
  phone: string,
  is_active: boolean
}

const Users = () => {
  const [viewProfile, setViewProfile] = useState(false);
  const [searchData, setSearchData] = useState('');

  const [users, setUsers] = useState<User[]>([
    {
      id: 0,
      name: '',
      email: '',
      cpf: '',
      office: '',
      company: '',
      cnpj: '',
      address_cep_address: '',
      endereco2: '',
      cep_address: '',
      cep_cep_address: '',
      num_cep_address: 0,
      complement: '',
      neighborhood_cep_address: '',
      city_cep_address: '',
      uf_cep_address: '',
      country_cep_address: '',
      phone: '',
      is_active: true
    }
  ]);

  const [userSelected, setUserSelected] = useState<User>({...users[0]})

  const handleViewProfile = (id: number) => {
    const userSelectedProfile: User = users.filter(obj => obj.id === id)[0];

    setUserSelected({
      id: id,
      name: userSelectedProfile['name'],
      email: userSelectedProfile['email'],
      cpf: userSelectedProfile['cpf'],
      office: userSelectedProfile['office'],
      company: userSelectedProfile['company'],
      cnpj: userSelectedProfile['cnpj'],
      address_cep_address: userSelectedProfile['address_cep_address'],
      endereco2: '',
      cep_address: userSelectedProfile['cep_address'],
      cep_cep_address: userSelectedProfile['cep_cep_address'],
      num_cep_address: userSelectedProfile['num_cep_address'],
      complement: userSelectedProfile['cep_address'],
      neighborhood_cep_address: userSelectedProfile['neighborhood_cep_address'],
      city_cep_address: userSelectedProfile['city_cep_address'],
      uf_cep_address: userSelectedProfile['uf_cep_address'],
      country_cep_address: userSelectedProfile['country_cep_address'],
      phone: userSelectedProfile['phone'],
      is_active: userSelectedProfile['is_active'],
    });
    setViewProfile(true);
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
            const profileRequest = await profile('admin-amz');
            setUsers(profileRequest);
            // setActiveLoading(false);
            registerUserLogs("Visualizou lista de usuários.");
        } catch (error) {
            console.error('Erro ao buscar perfil:', error);
        }
    };

    fetchData();
}, []);

const handleSearch = (event: any) => {
  setSearchData(event.target.value)
}

  return (
    <div>
      {viewProfile ? (
        <UsersProfile userSelected={userSelected} />
      ) : (
      <div className="mt-20 p-4 md:p-8 animate-fade-left">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col justify-center">
            <h1 className="text-2xl font-bold mb-4 text-center">Usuários</h1>
            <p className="mb-6 text-center">Lista de usuários cadastrados no sistema e suas demais informações.</p>
          </div>
          <button 
            className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300 h-[40px]"
            onClick={() => window.location.href = 'register-profile'}
          >
            Novo perfil
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-300">Nome</th>
                <th className="py-2 px-4 border-b border-gray-300">Email</th>
                <th className="py-2 px-4 border-b border-gray-300">Tipo de Perfil</th>
                <th className="py-2 px-4 border-b border-gray-300">Ações</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} className="text-center">
                  <td className="py-2 px-4 border-b border-gray-300">{user.name}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{user.email}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{user.office}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <button 
                      className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                      onClick={() => handleViewProfile(user.id)}
                    >
                      Visualizar Perfil
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>)}
    </div>
  );
};

export default Users;
