import jsPDF from "jspdf";
import "./relatory.css"
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar/navbar";
import { cnpjUser } from "../../api/cnpjUsers";
import { filesRelatorySpreadsheet, filterProjectsApproveds, qrcodeRelatory } from "../../api/spreadsheets";
import { dataCompany } from "../../api/dataCompany";
import axios from "axios";
import Loading from "../../components/Loading/loading";
import MessageRequest from "../../components/CardMessage/MessageRequest/messageRequest";
import { registerReport, filtredReport, editReport, registerAccomplishedsTerms } from "../../api/reports_client";
import FileUploadArea from "../../components/FileUploadArea/fileUploadArea";
import { InputAdornment } from "@mui/material";
import { registerUserLogs } from "../../api/userLogs";
import { PDFDocument } from 'pdf-lib';
import { stages } from "../../api/categoriesStages";
import { detailsStages } from "./utils";
import { generatePdfRelatory } from "../../api/generatePdf";

interface Stage {
    id: number;
    stage: string;
}

const Relatory = () => {
    const { id } = useParams();
    const [inputName, setinputName] = useState<string>('');
    const [inputAddress, setinputAddress] = useState<string>('');
    const [inputCep, setinputCep] = useState<string>('');
    const [inputCityUf, setinputCityUf] = useState<string>('');
    const [inputEdiction1, setinputEdiction1] = useState<string>('');
    const [inputEdiction2, setinputEdiction2] = useState<string>('');
    const [inputEdiction3, setinputEdiction3] = useState<string>('');
    const [inputReview1, setinputReview1] = useState<string>('');
    const [inputReview2, setinputReview2] = useState<string>('');
    const [inputReview3, setinputReview3] = useState<string>('');
    const [inputLayout, setinputLayout] = useState<string>('');
    const [inputCoordination1, setinputCoordination1] = useState<string>('');
    const [inputCoordination2, setinputCoordination2] = useState<string>('');
    const [inputCoordination3, setinputCoordination3] = useState<string>('');
    const [inputPresident, setinputPresident] = useState<string>('');
    const [inputStakeholder, setinputStakeholder] = useState<string>('');
    const [inputGoal, setinputGoal] = useState<string>('');
    const [inputMethodology, setinputMethodology] = useState<string>('');
    const [inputCompany, setinputCompany] = useState<string>('');
    const [inputLogo, setinputLogo] = useState<string>('');
    const [dayStart, setDayStart] = useState<string>('');
    const [dayEnd, setDayEnd] = useState<string>('');
    const [inputMonth, setInputMonth] = useState<string>('');
    const [inputMonthEnd, setInputMonthEnd] = useState<string>('');
    const [inputYear, setInputYear] = useState<string>('');
    const [noteAudictionStart, setNoteAudictionStart] = useState<string>('');
    const [noteAudictionEnd, setNoteAudictionEnd] = useState<string>('');
    const [accomplished, setAccomplished] = useState<any[]>([]);
    const [term, setTerm] = useState<any[]>([]);
    const [inputNameRelatory, setInputNameRelatory] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [projects, setProjects] = useState([]);
    const [userId, setUserId] = useState('');
    const [stageId, setStageId] = useState<number>(0);
    const [stageName, setStageName] = useState('');
    const [stageDetails, setStageDetails] = useState('');
    const [service, setService] = useState(0);
    const [nameRelatory, setNameRelatory] = useState('');
    const iframeRef: any = useRef(null);
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [stageImage, setStageImage] = useState('');
    const [activeLoading, setActiveLoading] = useState(false);
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [bestSpreadsheets, setBestSpreadsheets] = useState<any[]>([]);
    const [nameFile, setNameFile] = useState<string>('');
    const [fileUpload, setFileUpload] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<string>('');
    const [finalConsiderations, setFinalConsiderations] = useState('');
    const [filesRelatory, setFilesRelatory] = useState([]);
    const [urlQrCode, setUrlQrCode] = useState('');
    const paragraphs = inputCompany.split('/n')
    const [dataStages, setStages] = useState<Stage[]>([]);

    const handleFileSelect = (file: any) => {
        setNameFile(file.name);
        setFileUpload(file);

        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') {
                setSelectedFile(result);
            } 
        };
        reader.readAsDataURL(file);
    };

    const loadPDF = (linkFile: string) => {
        if (iframeRef.current) {
            iframeRef.current.src = linkFile;

            return iframeRef
        }
    };

    const typeImage = (nameImage: string) => {
        const detailsName = String(nameImage).split('.')
        
        return detailsName[1]
    }

    const fetchFiles = async (id: number) => {
        const filesAudit = await filesRelatorySpreadsheet(id);
        setFilesRelatory(filesAudit);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setActiveLoading(false);
                setActiveLoading(true);
                const responseReport = await filtredReport(id);
                console.log(responseReport);
                setUserId(String(responseReport.user_id));
                fetchFiles(parseInt(responseReport.user_id));
                if (stageId) {
                    const companyDataRequest = await dataCompany(String(responseReport.user_id), stageId!);
                    setInputNameRelatory(responseReport.title && responseReport.title);
                    setSelectedFile((responseReport.image) ? responseReport.image.link : '');
                    setinputEdiction1(responseReport.ediction1 ? responseReport.ediction1 : '');
                    setinputEdiction2((responseReport.ediction2) ? responseReport.ediction2 : '');
                    setinputEdiction3(responseReport.ediction3 ? responseReport.ediction3 : '');
                    setinputReview1(responseReport.review1 ? responseReport.review1 : '');
                    setinputReview2(responseReport.review2 ? responseReport.review2 : '');
                    setinputReview3(responseReport.review3 ? responseReport.review3 : '');
                    setinputLayout(responseReport.layout ? responseReport.layout : '');
                    setinputCoordination1(responseReport.coordination1 ? responseReport.coordination1 : '');
                    setinputCoordination2(responseReport.coordination2 ? responseReport.coordination2 : '');
                    setinputCoordination3(responseReport.coordination3 ? responseReport.coordination3 : '');
                    setinputPresident(responseReport.president ? responseReport.president : '');
                    setinputStakeholder(responseReport.techlead ? responseReport.techlead : '');
                    setinputGoal(responseReport.goal ? responseReport.goal : '');
                    setinputMethodology(responseReport.methodology ? responseReport.methodology : '');
                    setDayStart(responseReport.start_date ? responseReport.start_date : '');
                    setDayEnd(responseReport.end_date ? responseReport.end_date : '');
                    setInputMonth(responseReport.month ? responseReport.month : '');
                    setInputYear(responseReport.year ? responseReport.year : '');
                    setNoteAudictionStart(responseReport.start_note ? responseReport.start_note : '');
                    setNoteAudictionEnd(responseReport.end_note ? responseReport.end_note : '');
                    setAccomplished(responseReport.accomplished_relatory ? responseReport.accomplished_relatory.length > 0 ? responseReport.accomplished_relatory : generateAccomplished() : []);
                    setTerm(responseReport.term_relatory ? responseReport.term_relatory.length > 0 ? responseReport.term_relatory : generateTerm() : []);
                    setFinalConsiderations((responseReport.final_considerations) ? responseReport.final_considerations : '');
                    setinputName(companyDataRequest.user.company || '');
                    setinputCompany(companyDataRequest.user.description_company || '');
                    setinputLogo(companyDataRequest.user.image.link || '')
                    setinputAddress(companyDataRequest.user.address_cep_address || '');
                    setinputCep(companyDataRequest.user.cep_cep_address || '');
                    setinputCityUf(`${companyDataRequest.user.city_cep_address} - ${companyDataRequest.user.uf_cep_address} - ${companyDataRequest.user.country_cep_address}` || '');
                    setSpreadsheets(companyDataRequest.spreadsheets[0] || []);
                    setStageImage(companyDataRequest.spreadsheets[0].length > 0 ? companyDataRequest.spreadsheets[0][0]['stage_id_image']['link'] : []);
                    setBestSpreadsheets(companyDataRequest.best_spreadsheets ? companyDataRequest.best_spreadsheets : []);
                }
            }
            setActiveLoading(false)
        }

        const fetchStages = async () => {
            const response = await stages() 
            setStages(response);
        }
        
        fetchData();
        fetchStages()
    }, [stageId])

    useEffect(() => {
        const fetchData = async () => {
            const projectsDataRequest = await filterProjectsApproveds(true);
            setProjects(projectsDataRequest);
        }

        fetchData()
    }, []);

    const generateAccomplished = () => {
        let listAccomplisheds = [];
        if (spreadsheets.length > 0) {
            for(let item of spreadsheets) {
                listAccomplisheds.push('')
            }
        }

        setAccomplished(listAccomplisheds);
    };

    const generateTerm = () => {
        let listTerms = [];
        if (spreadsheets.length > 0) {
            for(let item of spreadsheets) {
                listTerms.push('')
            }
        }

        setTerm(listTerms);
    };

    const generatedPdfRelatory = async (qr: string) => {
        const response = await generatePdfRelatory(
            nameRelatory, 
            selectedFile, 
            inputName, 
            stageName, 
            stageDetails, 
            inputEdiction1, 
            inputEdiction2, 
            inputEdiction3, 
            inputReview1, 
            inputReview2, 
            inputReview3, 
            inputCoordination1, 
            inputCoordination2, 
            inputCoordination3, 
            inputCompany, 
            inputAddress, 
            inputCep, 
            inputCityUf, 
            inputPresident, 
            inputStakeholder, 
            inputGoal, 
            inputMethodology, 
            dayStart, 
            dayEnd, 
            inputMonth, 
            inputYear, 
            noteAudictionStart, 
            noteAudictionEnd, 
            stageImage, 
            spreadsheets, 
            finalConsiderations, 
            filesRelatory, 
            qr,
            accomplished,
            term
        );

        return response;
    }

    const handleConvertToPdf = async () => {
        setActiveLoading(true);
        
        const pdf = await generatedPdfRelatory('');
        
        const formData = new FormData();
        formData.append('file', new Blob([pdf], { type: 'application/pdf' }), inputNameRelatory);
        
        const qrCode = await qrcodeRelatory(formData);
        if (qrCode.link !== '') {
            setUrlQrCode(qrCode.link);
            const pdfWithQrCode = await generatedPdfRelatory(qrCode.link);
            if (pdfWithQrCode instanceof Blob) {
                const url = URL.createObjectURL(pdfWithQrCode); 
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank'; 
                link.click();
                URL.revokeObjectURL(url); 
            } else if (pdfWithQrCode instanceof ArrayBuffer) {
                const blob = new Blob([pdfWithQrCode], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.click();
                URL.revokeObjectURL(url);  
            } else {
                console.error('O retorno não é um Blob ou ArrayBuffer:', pdfWithQrCode);
            }
        }

        setGenerating(false);
        setActiveLoading(false);
        registerUserLogs("Gerou relatório.");
    }

    const handleGenerateRelatory = async () => {
        setActiveLoading(true);
        try{
            const formData = new FormData();
            if (fileUpload !== null) {
                formData.append('image', fileUpload);
            }
            formData.append('title', inputNameRelatory);
            formData.append('user_id', userId);
            formData.append('ediction1', inputEdiction1);
            formData.append('ediction2', inputEdiction2);
            formData.append('ediction3', inputEdiction3);
            formData.append('review1', inputReview1);
            formData.append('review2', inputReview2);
            formData.append('review3', inputReview3);
            formData.append('layout', inputLayout);
            formData.append('coordination1', inputCoordination1);
            formData.append('coordination2', inputCoordination2);
            formData.append('coordination3', inputCoordination3);
            formData.append('president', inputPresident);
            formData.append('techlead', inputStakeholder);
            formData.append('goal', inputGoal);
            formData.append('methodology', inputMethodology);
            formData.append('start_date', dayStart);
            formData.append('end_date', dayEnd);
            formData.append('month', inputMonth);
            formData.append('year', inputYear);
            formData.append('start_note', noteAudictionStart);
            formData.append('end_note', noteAudictionEnd);
            formData.append('final_considerations', finalConsiderations);

            const responseRelatory: any = await registerReport(
                formData
            );
                
            if (responseRelatory.status === 201) {
                registerAccomplishedsTerms(inputNameRelatory, accomplished, term);
                setActiveLoading(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Salvo!');
                setMessageRequest('Relatório salvo com sucesso.');
                setUrlQrCode('');
            } else {
                setActiveLoading(false);
                setActiveMessageRequest(false);
                setActiveMessageRequest(true);
                setTitleMessageRequest('Erro!');
                setMessageRequest('Título, capa e cliente são obrigatórios.');
            }
        } catch(e) {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Título, capa e cliente são obrigatórios.');
        }
    }

    const handleEditRelatory = async () => {
        const formData = new FormData();
        if (fileUpload !== null) {
            formData.append('image', fileUpload);
        }
        formData.append('id', id ? id : '');
        formData.append('title', inputNameRelatory);
        formData.append('user_id', userId);
        formData.append('ediction1', inputEdiction1);
        formData.append('ediction2', inputEdiction2);
        formData.append('ediction3', inputEdiction3);
        formData.append('review1', inputReview1);
        formData.append('review2', inputReview2);
        formData.append('review3', inputReview3);
        formData.append('layout', inputLayout);
        formData.append('coordination1', inputCoordination1);
        formData.append('coordination2', inputCoordination2);
        formData.append('coordination3', inputCoordination3);
        formData.append('president', inputPresident);
        formData.append('techlead', inputStakeholder);
        formData.append('goal', inputGoal);
        formData.append('methodology', inputMethodology);
        formData.append('start_date', dayStart);
        formData.append('end_date', dayEnd);
        formData.append('month', inputMonth);
        formData.append('year', inputYear);
        formData.append('start_note', noteAudictionStart);
        formData.append('end_note', noteAudictionEnd);
        formData.append('final_considerations', finalConsiderations);

        const responseRelatory: any = await editReport(
            formData
        );
            
        if (responseRelatory.status === 200) {
            registerAccomplishedsTerms(inputNameRelatory, accomplished, term);
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Salvo!');
            setMessageRequest('Relatório salvo com sucesso.');
            registerUserLogs("Criou/editou relatório.");
            setUrlQrCode('');
        } else {
            setActiveLoading(false);
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro!');
            setMessageRequest('Verifique os dados e tente novamente.');
        }
    }

    const handleCancelPdf = () => {
        window.location.href = "/relatorios"
    }

    const handleUserId = async (event: any) => {
        setActiveLoading(true)
        setUserId(event.target.value);
        fetchFiles(parseInt(event.target.value));
        if (stageId) {
            const companyDataRequest = await dataCompany(event.target.value, stageId!);
            setinputName(companyDataRequest.user.company || '');
            setinputCompany(companyDataRequest.user.description_company || '');
            setinputLogo(companyDataRequest.user.image.link || '')
            setinputAddress(companyDataRequest.user.address_cep_address || '');
            setinputCep(companyDataRequest.user.cep_cep_address || '');
            setinputCityUf(`${companyDataRequest.user.city_cep_address} - ${companyDataRequest.user.uf_cep_address} - ${companyDataRequest.user.country_cep_address}` || '');
            setSpreadsheets(companyDataRequest.spreadsheets[0] || []);   
            setBestSpreadsheets(companyDataRequest.best_spreadsheets ? companyDataRequest.best_spreadsheets : []);
        }
        setActiveLoading(false);
    }

    const handleStage = (event: any) => {
        setActiveLoading(true)
        setStageId(event.target.value);
        setStageName(detailsStages.filter((item) => item.id === parseInt(event.target.value))[0].name);
        setStageName(detailsStages.filter((item) => item.id === parseInt(event.target.value))[0].name);
        setStageDetails(detailsStages.filter((item) => item.id === parseInt(event.target.value))[0].details);
        setActiveLoading(false)
    }

    useEffect(() => {
        setSpreadsheets(spreadsheets);
    }, [spreadsheets])

    const handleInputName = (event: any) => {
        setNameRelatory(event.target.value);
    }

    const handleInputAddress = (event: any) => {
        setinputAddress(event.target.value);
    }

    const handleInputCep = (event: any) => {
        setinputCep(event.target.value);
    }

    const handleInputCityUf = (event: any) => {
        setinputCityUf(event.target.value);
    }

    const handleInputEdiction1 = (event: any) => {
        setinputEdiction1(event.target.value);
    }

    const handleInputEdiction2 = (event: any) => {
        setinputEdiction2(event.target.value);
    }

    const handleInputEdiction3 = (event: any) => {
        setinputEdiction3(event.target.value);
    }

    const handleInputReview1 = (event: any) => {
        setinputReview1(event.target.value);
    }

    const handleInputReview2 = (event: any) => {
        setinputReview2(event.target.value);
    }

    const handleInputReview3 = (event: any) => {
        setinputReview3(event.target.value);
    }

    const handleInputLayout = (event: any) => {
        setinputLayout(event.target.value);
    }

    const handleInputCoordination1 = (event: any) => {
        setinputCoordination1(event.target.value);
    }
    
    const handleInputCoordination2 = (event: any) => {
        setinputCoordination2(event.target.value);
    }

    const handleInputCoordination3 = (event: any) => {
        setinputCoordination3(event.target.value);
    }

    const handleInputPresident = (event: any) => {
        setinputPresident(event.target.value);
    }

    const handleInputStakeholder = (event: any) => {
        setinputStakeholder(event.target.value);
    }

    const handleInputGoal = (event: any) => {
        setinputGoal(event.target.value);
    }

    const handleInputMethodology = (event: any) => {
        setinputMethodology(event.target.value);
    }

    const handleInputCompany = (event: any) => {
        setinputCompany(event.target.value);
    }

    const handleFinalConsiderations = (event: any) => {
        setFinalConsiderations(event.target.value);
    }

    const handleDayStart = (event: any) => {
        setDayStart(event.target.value);
    }

    const handleDayEnd = (event: any) => {
        setDayEnd(event.target.value);
    }

    const handleInputMonth = (event: any) => {
        setInputMonth(event.target.value);
    }

    const handleInputYear = (event: any) => {
        setInputYear(event.target.value);
    }

    const handleNoteAudictionStart = (event: any) => {
        setNoteAudictionStart(event.target.value);
    }

    const handleNoteAudictionEnd = (event: any) => {
        setNoteAudictionEnd(event.target.value);
    }

    const handleAccomplished = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        setAccomplished(prevState => {
            const updatedAccomplished = [...prevState]; 
            updatedAccomplished[index] = value;
            return updatedAccomplished;
        });
    }

    const handleTerm = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        setTerm(prevState => {
            const updatedTerm = [...prevState]; 
            updatedTerm[index] = value;
            return updatedTerm;
        });
    }

    const handleInputNameRelatory = (event: any) => {
        setInputNameRelatory(event.target.value);
    }

    return (
        <div className="background-relatory" style={{ margin: 0, padding: 0 }}>
            <Navbar title="Sistema AMZ" user={true} buttonSave={true} functionSave={id ? handleEditRelatory : handleGenerateRelatory} buttonDownload={true} functionDownload={handleConvertToPdf} functionBack={() => window.location.href = '/home-page'} />
            <div className="container-relatory animate-fade-left">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="container-title pt-6">
                        <img
                            src="/amz.png"
                            alt="Imagem de cadastro"
                            className="amz-relatory-image"
                        />
                        <h3 className="title-relatory">* Relatório</h3>
                        <input type="text" onChange={handleInputNameRelatory} className="border border-black px-2" value={inputNameRelatory} style={{ width: "260px", marginLeft: "20px" }}/>
                    </div>
                    <h3 className="title-relatory pt-4">* Capa</h3>
                    <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} />
                </div>
            </div>
            <div style={{ width: "100%", height: "100%", display: activeLoading ? "flex" : "none", position: "fixed", zIndex: "999" }}>
                <Loading activeProps={activeLoading} />
            </div>
            <div className="animate-fade-left" style={{ marginTop: "380px" }}>
                <div style={{ height: "100%", display: activeMessageRequest ? "flex" : "none", position: "fixed", zIndex: "999" }}>
                    <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />
                </div>
                <div id="content-relatory" className="content-relatory">
                    <main className="cover">
                        {!nameFile && selectedFile ? (<img className="img-cover" src={`data:image/jpeg;base64,${selectedFile}`} />) : (<img className="img-cover" src={`${selectedFile}`} />)}
                        <div className="area-body-cover">
                            <div className="area-logo-relatory">
                                {urlQrCode === '' ? (
                                    <div></div>
                                ) : (
                                    <img src={`data:image/jpeg;base64,${urlQrCode}`} alt="" style={{ width: "80px", height: "80px", marginRight:"20px", marginTop: "425px", zIndex: 999, position: "absolute" }} />
                                )}
                                <img src="/images_relatory/icon_amz_relatory.png" alt="icon_amz_relatory" style={{ width: "80px", height: "130px", marginRight:"25px" }} />
                            </div>
                            <div className="flex mt-[220px] justify-center">
                                <div className="flex flex-col p-5 rounded shadow-lg w-80 justify-center bg-[#006633]">
                                    <h2 className="text-[#67BC28] text-[14px] font-bold uppercase">Etapa</h2>
                                    {generating ? (<h3 className="text-[#67BC28] text-[20px] font-bold">
                                        {stageName}
                                    </h3>) : (
                                        <select className="select-register py-1" style={{ width: "260px" }} value={stageId} onChange={handleStage}>
                                            <option value="">Selecione...</option>
                                            {dataStages.map((stage: { id: number, stage: string }, index: number) => (
                                                <option key={index} value={stage.id}>
                                                    {stage.stage}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                            <div className="area-title">
                                <div className="col-title-area" style={{ height: generating ? "100px" : "150px", paddingTop: generating ? 0 : "30px", paddingBottom: generating ? "80px" : "30px" }}>
                                    <h2 className="title-cover">
                                        RELATÓRIO  DE  AUDITORIA 
                                    </h2>
                                    <h2 className="title-cover">
                                        E  CERTIFICAÇÃO  AMBIENTAL
                                    </h2>
                                    {generating ? (<h3 className="subtitle-cover" style={{ marginBottom: "80px" }}>
                                        {inputName}
                                    </h3>) : (
                                        <select className="select-register py-1" style={{ width: "260px" }} value={userId} onChange={handleUserId}>
                                            <option value="">Selecione...</option>
                                            {projects.map((project: any, index: number) => (
                                                <option key={index} value={project.stakeholder}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            </div>
                        </div>
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                        <div className="top-cover-against">
                            <img src="/images_relatory/amz_relatory.png" alt="amz_relatory" style={{ width: "80px", height: "100px" }} />
                            <div className="flex flex-row justify-start w-[315px]" style={{ margin: 0 }}>
                                <h2 className="text-[12px] text-justify text-white font-bold">
                                    { stageDetails }
                                </h2>
                            </div>
                        </div>
                        <div className="area-stakeholders">
                            <div className="col-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right", fontWeight: 'bold' }}>
                                    Edição
                                </h4>
                                {generating ? (
                                    <div className="mt-3" style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputEdiction1}
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputEdiction2}
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputEdiction3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input className="border border-black mb-2 px-2" type="text" onChange={handleInputEdiction1} value={inputEdiction1} style={{ width: "260px" }}/>
                                        <input className="border border-black px-2" type="text" onChange={handleInputEdiction2} value={inputEdiction2} style={{ width: "260px" }}/>
                                        <input className="border border-black mt-2 px-2" type="text" onChange={handleInputEdiction3} value={inputEdiction3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                            <div className="col-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Revisão
                                </h4>
                                {generating ? (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputReview1}
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputReview2}
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputReview3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input className="border border-black mb-2 px-2" type="text" onChange={handleInputReview1} value={inputReview1} style={{ width: "260px" }}/>
                                        <input className="border border-black px-2" type="text" onChange={handleInputReview2} value={inputReview2} style={{ width: "260px" }}/>
                                        <input className="border border-black mt-2 px-2" type="text" onChange={handleInputReview3} value={inputReview3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                            <div className="col-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Diagramação
                                </h4>
                                {generating ? (
                                    <p style={{ width: "260px", fontSize: "14px" }}>
                                        {inputLayout}
                                    </p>
                                ) : (
                                    <input className="border border-black px-2" type="text" onChange={handleInputLayout} value={inputLayout} style={{ width: "260px" }}/>
                                )}
                            </div>
                            <div className="col-stakeholder">
                                <h4 className="title-stakeholder" style={{ textAlign: "right" }}>
                                    Coordenação
                                </h4>
                                {generating ? (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputCoordination1}
                                            
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputCoordination2}
                                        </p>
                                        <p style={{ width: "260px", fontSize: "14px" }}>
                                            {inputCoordination3}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <input className="border border-black mb-2 px-2" type="text" onChange={handleInputCoordination1} value={inputCoordination1} style={{ width: "260px" }}/>
                                        <input className="border border-black px-2" type="text" onChange={handleInputCoordination2} value={inputCoordination2} style={{ width: "260px" }}/>
                                        <input className="border border-black mt-2 px-2" type="text" onChange={handleInputCoordination3} value={inputCoordination3} style={{ width: "260px" }}/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div style={{ display: "flex", flexDirection: "row"}}>
                            <div className="data-company">
                                <div className="flex items-start bg-white">
                                    <div className="bg-green-700 h-full w-2"></div>
                                    <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>SUMÁRIO</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                                <div className="company">
                                <img src="/images_relatory/sumario_relatorio.png" alt="sumario_relatorio" style={{ width: "100%", marginTop: "80px" }} />
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="data-company" style={{ paddingRight: "20px", width: "435px" }}>
                                <div className="flex items-start bg-white">
                                    <div className="bg-green-700 h-full w-5"></div>
                                    <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>APRESENTAÇÃO</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                    
                                </div>
                                <div className="company">
                                    {generating ? (
                                        paragraphs.length && paragraphs.map((value) => (
                                            <p style={{ textAlign: "justify", fontSize: "12px" }}>{value.replace("/n", " ")}</p>
                                        ))
                                    ) : (
                                        <textarea onChange={handleInputCompany} value={inputCompany} cols={50} rows={8} className="mt-2 shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" style={{"resize": "none", width: "100%", height: "440px"}}   />
                                    )}
                                </div>
                            </div>
                        </div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                        <div style={{height: '79px'}}></div>
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div className="data-company" style={{ height: "270px !important", justifyContent: "flex-start"}}>
                            <div className={generating ? "flex items-start bg-white mt-10" : "flex items-start bg-white"}>
                                <div className="bg-green-700 h-full w-2"></div>
                                    <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>EMPRESA</h1>
                                <div className="flex-grow bg-white"></div>
                                <div className="bg-green-700 h-full w-full"></div>
                            </div>
                            <div className="company max-h-[250px]" style={{ padding: "0"}}>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        SISTEMA DE CERTIFICAÇÃO AMAZON 
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                <h5 className="title-data-company text-[12px] tracking-wider">
                                        Av. Perimetral, 362 - Marco | PCT Guamá - Espaço Empreendedor 
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        66077-830 
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        Belém - PA - Brasil
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        +55 91 99346-8595
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        contato@amzbrazil.com.br
                                    </h5>
                                </div>
                                <div className="row-data-company">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        www.amzbrazil.com.br
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col px-[35px]" style={{height: "150px !important"}} >
                            <div className="flex items-start bg-white">
                                <div className="bg-green-700 h-full w-2"></div>
                                <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>ORGANIZAÇÃO</h1>
                                <div className="flex-grow bg-white"></div>
                                <div className="bg-green-700 h-full w-full"></div>
                                
                            </div>
                            <div className="company" style={{ padding: "0", width: "100%" }}>
                                <div className="w-full">
                                    <h5 className="title-data-company text-[12px] tracking-wider">
                                        {inputName}
                                    </h5>
                                </div>
                                <div className="w-full">
                                    {generating ? (<h5 className="title-data-company text-[12px] tracking-wider">
                                        {inputAddress}
                                    </h5>) : (
                                        <input className="border border-black px-2 w-full" placeholder="Av. Perimetral, 362 - Marco" type="text" onChange={handleInputAddress} value={inputAddress} style={{ margin: "0 0 10px 0" }}/>
                                    )}
                                </div>
                                <div className="w-full">
                                    {generating ? (<h5 className="title-data-company text-[12px] tracking-wider">
                                        {inputCep}
                                    </h5>) : 
                                        <input className="border border-black px-2 w-full" placeholder="Cep: XXXXX-XXX" type="text" onChange={handleInputCep} value={inputCep} style={{ margin: "0 0 10px 0" }}/>
                                    }
                                </div>
                                <div className="w-full">
                                    {generating ? (<h5 className="title-data-company text-[12px] tracking-wider">
                                        {inputCityUf}
                                    </h5>) : (
                                        <input className="border border-black px-2 w-full" placeholder="Belém - PA - Brasil" type="text" onChange={handleInputCityUf} value={inputCityUf} style={{ margin: "0 0 10px 0" }}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                        <div style={{ display: "flex", flexDirection: "column"}}>
                            <div className="data-company" style={{ height: "230px" }}>
                                <div className="flex items-start bg-white">
                                    <div className="bg-green-700 h-full w-2"></div>
                                        <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>AUDITORIA</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                                <div className="flex flex-col justify-between w-full">
                                    <div className="container-president-company" style={{width: '100%', height: '100px'}}>
                                        <h5 className="president-company">PRESIDENTE</h5>
                                        {generating ? (<p className="president-name tracking-wider" style={{ marginBottom: generating ? "30px" : "0" }}>{inputPresident}</p>) : (
                                            <input className="border border-black px-2" type="text" onChange={handleInputPresident} value={inputPresident} style={{ width: "355px", margin: "0" }}/>
                                        )}
                                    </div>
                                    <div className="container-technician-company" style={{width: '100%', height: '100px'}}>
                                        <h5 className="technician-company tracking-wider">RESPONSÁVEL TÉCNICO</h5>
                                        {generating ? (
                                            <p className="technician-name tracking-wider" style={{ marginBottom: generating ? "30px" : "0" }}>{inputStakeholder}</p>
                                        ) : (
                                            <input className="border border-black px-2" type="text" onChange={handleInputStakeholder} value={inputStakeholder} style={{ width: "355px", margin: "0" }}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="data-company" style={{ height: "160px", marginTop: "30px" }}>
                                <div className={generating ? "flex items-start bg-white mt-8" : "flex items-start bg-white mt-5"}>
                                    <div className="bg-green-700 h-full w-2"></div>
                                        <h1 className={generating ? "text-black font-bold px-4 mb-3" : "text-black font-bold px-4"}>OBJETIVO</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px" }}>{inputGoal} </p>
                                    ) : (
                                        <textarea  className="mt-2 shadow appearance-none border border-black rounded w-full pt-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  onChange={handleInputGoal} value={inputGoal} cols={50} rows={8} style={{"resize": "none", height: "70px"}} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                        <div style={{ display: "flex", flexDirection: "column"}}>
                            <div className="data-company" style={{ height: "400px" }}>
                            <div className={generating ? "flex items-start bg-white mt-5" : "flex items-start bg-white mt-5"}>
                                    <div className="bg-green-700 h-full w-2"></div>
                                        <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>METODOLOGIA</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px", height: "300px" }}>{inputMethodology} </p>
                                    ) : (
                                        <textarea className="mt-2 shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  onChange={handleInputMethodology} value={inputMethodology} cols={50} rows={8} style={{"resize": "none", height: "300px"}}   />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className="data-company" style={{ height: "40px" }}>
                                <div className="flex items-start bg-white">
                                    <div className="bg-green-700 h-full w-2"></div>
                                    <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>PERÍODO</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                            </div>
                            <div className="row-data-company" style={{ height: "400px", paddingLeft: "70px", alignItems: "flex-start", justifyContent: "space-between" }}>
                                <div className="col-period ml-6" style={{ marginRight: "5px" }}>
                                    {generating ? (
                                        <h5 className="days-audiction">{dayStart} à {dayEnd}</h5>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <input className="border border-black px-2" placeholder="Início" type="text" onChange={handleDayStart} value={dayStart} style={{ width: "50px", margin: "0 5px 10px 0" }}/>
                                            <p>à</p>
                                            <input className="border border-black px-2" placeholder="Fim" type="text" onChange={handleDayEnd} value={dayEnd} style={{ width: "50px", margin: "0 0 10px 5px" }}/>
                                        </div>
                                    )}
                                    {generating ? (
                                        <p>{inputMonth}</p> 
                                    ) : (
                                        <input className="border border-black px-2" placeholder="Mês" type="text" onChange={handleInputMonth} value={inputMonth} style={{ width: "60px", margin: "0 5px 10px 0" }}/>
                                    )}
                                    {generating ? (
                                        <p>{inputYear}</p> 
                                    ) : (
                                        <input className="border border-black px-2" placeholder="Ano" type="text" onChange={handleInputYear} value={inputYear} style={{ width: "60px", margin: "0 5px 10px 0" }}/>
                                    )}
                                </div>
                                <div className="col-period">
                                    <div className="flex flex-row" style={{ marginBottom: "20px", width: "250px", }}>
                                        <div className="flex flex-col justify-between ml-2 mt-3">
                                            <div className="flex flex-row ml-[50px]">
                                                <div className="flex flex-col mr-5">
                                                    <h5 className="day-audiction">{dayStart}</h5>
                                                    <p className="month-audiction">de {inputMonth}</p>
                                                </div>
                                                <div className="flex flex-col ml-[32px]">
                                                    <h5 className="day-audiction">{dayEnd}</h5>
                                                    <p className="month-audiction">de {inputMonth}</p>
                                                </div>
                                            </div>
                                            <img src="/images_relatory/linha.png" alt="linha" className="mt-3" />
                                        </div>
                                    </div>
                                    <div className="result-audiction mt-12">
                                        <div className="container-technician-result" style={{ marginRight: "20px" }}>
                                            {generating ? (
                                                <p className="technician-name" style={{ margin: 0, marginBottom: generating ? "30px" : "0" }}>{noteAudictionStart}</p>
                                            ) : (
                                                <input className="border border-black px-2" placeholder="Nota" type="text" onChange={handleNoteAudictionStart} value={noteAudictionStart} style={{ width: "60px", margin: "0 0 10px 5px" }}/>
                                            )}
                                        </div>
                                        <div className="container-technician-result">
                                        {generating ? (
                                                <p className="technician-name" style={{ margin: 0, marginBottom: generating ? "30px" : "0" }}>{noteAudictionStart}</p>
                                            ) : (
                                                <input className="border border-black px-2" placeholder="Nota" type="text" onChange={handleNoteAudictionEnd} value={noteAudictionEnd} style={{ width: "60px", margin: "0 0 10px 5px" }}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    {spreadsheets.map((spreadsheet: any, index: number) => (
                        <div key={index}>
                            <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div style={{height: '79px'}}></div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="data-company mt-3" style={{ height: "20px" }}>
                                        <div className="flex items-start bg-white">
                                            <div className="bg-green-700 h-full w-2"></div>
                                                <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>TABELA DE RESULTADOS</h1>
                                            <div className="flex-grow bg-white"></div>
                                        <div className="bg-green-700 h-full w-full"></div>
                                    </div>
                                    </div>
                                    <div className="flex flex-col px-[35px] mt-12">
                                        <div className="bg-green-700 h-[auto] w-full flex justify-center mt-[12px]">
                                            <h2 className={generating ? "text-white font-bold text-[12px] px-4 mb-4" : "text-white font-bold text-[12px] px-4"}>DESCRIÇÃO</h2>
                                        </div>
                                        <div className="flex flex-col" style={{ paddingBottom: generating ? "10.5px" : "1px" }}>
                                            <div className="flex flex-row gap-4 w-full" style={{height: '93px'}}>
                                                <div className="flex flex-row mt-1 mb-2 w-full">
                                                    <div className="flex flex-col w-full">
                                                        <div className="flex flex-row gap-2 border-b-[1px] pb-2 border-black w-full">
                                                            <h3 className="text-[8px] font-bold mr-5">
                                                                ETAPA
                                                            </h3>
                                                            <p className="data-relatory">{spreadsheet.stage_id_stage}</p>
                                                        </div>
                                                        <div className="flex flex-row gap-2 border-b-[1px] pb-2 border-black w-full">
                                                            <h3 className="text-[8px] font-bold mr-[3px]">
                                                                REQUISITO
                                                            </h3>
                                                            <p className="data-relatory">{spreadsheet.indicators_id_requirements}</p>
                                                        </div>
                                                        <div className="flex flex-row gap-2">
                                                            <h3 className="text-[8px] font-bold">
                                                                INDICADOR
                                                            </h3>
                                                            <p className="data-relatory text-justify">{spreadsheet.indicators_id_indicators}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bg-green-700 h-[auto] w-full mt-2 flex justify-center">
                                                <h2 className={generating ? "text-white font-bold text-[12px] px-4 mb-4" : "text-white font-bold text-[12px] px-4"}>AUDITORIA</h2>
                                            </div>
                                            <div className="flex flex-col w-full mt-2 mb-2">
                                                <h3 className="text-[8px] font-bold">
                                                    COMO FOI REALIZADO (E-MAILS, VIAGENS, OUTROS):
                                                </h3>
                                                <input className="border border-black px-2" placeholder="Digite..." type="text" onChange={(e) => handleAccomplished(e, index)} value={accomplished[index]} style={{ fontSize: "10px" }}/>
                                            </div>
                                            <div className="bg-green-700 h-[auto] w-full flex justify-center mt-4">
                                                <h2 className={generating ? "text-white font-bold text-[12px] px-4 mb-4" : "text-white font-bold text-[12px] px-4"}>ESCALA</h2>
                                            </div>
                                            <div className="flex flex-row justify-between mt-2">
                                                <div className="flex flex-col items-center">
                                                    <h3 className="text-[8px] font-bold mr-[3px]">
                                                        ATENDE
                                                    </h3>
                                                    <p className="text-[8px]">
                                                        {(spreadsheet.service ===  1 || spreadsheet.service === 2) ? "(X)" : "()"}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col items-center">
                                                    <h3 className="text-[8px] font-bold mr-[3px]">
                                                        ATENDE PARCIALMENTE
                                                    </h3>
                                                    <p className="text-[8px]">
                                                        {(spreadsheet.service ===  3) ? "(X)" : "( )"}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col items-center">
                                                    <h3 className="text-[8px] font-bold mr-[3px]">
                                                        NÃO ATENDE
                                                    </h3>
                                                    <p className="text-[8px]">
                                                        {(spreadsheet.service ===  4) ? "(X)" : "( )"}
                                                    </p>
                                                </div>
                                                <div className="flex flex-col items-center">
                                                    <h3 className="text-[8px] font-bold mr-[3px]">
                                                        NÃO SE APLICA
                                                    </h3>
                                                    <p className="text-[8px]">
                                                        {(spreadsheet.service ===  5) ? "(X)" : "( )"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-2">
                                                <h3 className="text-[8px] font-bold mr-[3px]">
                                                    EVIDÊNCIA APRESENTADA:
                                                </h3>
                                                <p className="text-[8px]">
                                                    {spreadsheet.evidence}
                                                </p>
                                            </div>
                                            <div className="flex flex-col mt-2">
                                                <h3 className="text-[8px] font-bold mr-[3px]">
                                                    OBSERVAÇÕES (IMPREVISTOS, SOLICITAÇÃO DE MAIOR PRAZO, ETC):
                                                </h3>
                                                <p className="text-[8px]">
                                                    {spreadsheet.observation}
                                                </p>
                                            </div>
                                            <div className="flex flex-col mt-2">
                                                <h3 className="text-[8px] font-bold mr-[3px]">
                                                    AÇÃO CORRETIVA OU SUGESTÃO DE MELHORIA:
                                                </h3>
                                                <p className="text-[8px]">
                                                    {spreadsheet.action}
                                                </p>
                                            </div>
                                            <div className="flex flex-col mt-2">
                                                <h3 className="text-[8px] font-bold mr-[3px]">
                                                    PRAZO PARA ATENDIMENTO:
                                                </h3>
                                                {generating ? (
                                                <p className="text-[8px]">{term || term[index] ? term[index] : ''}</p>
                                                ) : (
                                                    <input className="border border-black px-2" placeholder="Digite..." type="text" onChange={(e) => handleTerm(e, index)} value={term || term[index] ? term[index] : ''} style={{ fontSize: "10px" }}/>
                                                )}
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4" style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="data-company mb-2" style={{ height: "20px" }}>
                                        <div className="flex items-start bg-white">
                                            <div className="bg-green-700 h-full w-2"></div>
                                                <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>RESULTADO</h1>
                                            <div className="flex-grow bg-white"></div>
                                        <div className="bg-green-700 h-full w-full"></div>
                                    </div>
                                    </div>
                                    <div className="row-data-company" style={{ height: "100px" }}>
                                        <div className="data-company" style={{ height: "100px", marginBottom: generating ? "40px" : "0" }}>
                                            <div className="company">
                                                <p style={{ textAlign: "justify", fontSize: "12px" }}>{spreadsheet.result} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{height: '79px'}}></div>
                            </main>
                            {!generating && (
                                <div className="relative">
                                    <div className="w-full h-1 bg-[#E3E3E3]"></div>
                                </div>
                            )}
                        </div>
                    ))}
                    {/* <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="data-company">
                                <div className="company">
                                    <thead style={{ width: "100%", maxHeight: "465px", padding: 0, fontSize: "10px" }}>
                                        <tr style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <th style={{ paddingBottom: generating ?  "20px" : 0 }}>Resultado</th>
                                            <th style={{ paddingRight: "5px", paddingBottom: generating ?  "20px" : 0 }}>COD</th>
                                            <th style={{ paddingRight: "145px", paddingBottom: generating ?  "20px" : 0 }}>Indicadores</th>
                                            <th style={{ paddingRight: "5px", paddingBottom: generating ?  "20px" : 0 }}>Grau</th>
                                        </tr>
                                        <tbody style={{ minHeight: "465px" }}>
                                            {bestSpreadsheets[0] && bestSpreadsheets[0].map((spreadsheet: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="w-[75px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{spreadsheet.result}</td>
                                                    <td className="w-[50px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{spreadsheet.indicators_id_cod}</td>
                                                    <td className="text-justify border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{spreadsheet.indicators_id_indicators}</td>
                                                    <td className="w-[30px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{spreadsheet.critical_degree}</td>
                                                </tr>
                                            ))}
                                        </tbody> 
                                    </thead>
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main> */}
                    {/* {bestSpreadsheets && bestSpreadsheets.map((spreadsheet: any, index: number) => (
                        <div>
                            {index > 0 && (<main key={index} className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <div style={{height: '79px'}}></div>
                                <div style={{ display: "flex", flexDirection: "row", width: "367px" }}>
                                    <div className="data-company">
                                        <div className="company" style={{ width: "367px" }}>
                                            <thead style={{ width: "100%", maxWidth: "367px", maxHeight: "465px", padding: 0, fontSize: "10px" }}>
                                                <tbody style={{ maxHeight: "465px" }}>
                                                    {spreadsheet && spreadsheet.map((data: any, indexData: number) => (
                                                        <tr key={indexData}>
                                                            <td className="w-[75px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{(data) && data.result}</td>
                                                            <td className="w-[75px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{(data) && data.indicators_id_cod}</td>
                                                            <td className="text-justify border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{(data) && data.indicators_id_indicators}</td>
                                                            <td className="w-[75px] border h-[75px]" style={{ paddingBottom: generating ?  "20px" : 0, paddingTop: 0 }}>{(data) && data.critical_degree}</td>
                                                        </tr>
                                                    ))}
                                                </tbody> 
                                            </thead>
                                        </div>
                                    </div>
                                </div>
                                <div style={{height: '79px'}}></div>
                                {!generating && (
                                    <div className="relative">
                                        <div className="w-full h-1 bg-[#E3E3E3]"></div>
                                    </div>
                                )}
                            </main>)}
                        </div>
                    ))} */}
                    <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{height: '79px'}}></div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div className="data-company">
                                <div className="flex items-start bg-white">
                                    <div className="bg-green-700 h-full w-2"></div>
                                    <h1 className={generating ? "text-black font-bold px-4 mb-4" : "text-black font-bold px-4"}>CONSIDERAÇÕES FINAIS</h1>
                                    <div className="flex-grow bg-white"></div>
                                    <div className="bg-green-700 h-full w-full"></div>
                                </div>
                                <div className="company">
                                    {generating ? (
                                        <p style={{ textAlign: "justify", fontSize: "12px", width: "367px", height: "475px", overflow: "hidden" }}>{finalConsiderations} </p>
                                    ) : (
                                        <textarea className="mt-2 shadow appearance-none border border-black rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  onChange={handleFinalConsiderations} value={finalConsiderations} cols={50} rows={8} style={{"resize": "none", width: "100%", height: "410px"}}/>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{height: '79px'}}></div>
                        {!generating && (
                            <div className="relative">
                                <div className="w-full h-1 bg-[#E3E3E3]"></div>
                            </div>
                        )}
                    </main>
                    {filesRelatory && filesRelatory.map((file: any, index: number) => (
                        <div>
                            {String(file.name).split('.')[String(file.name).split('.').length - 1] !== 'pdf' && String(file.name).split('.')[String(file.name).split('.').length - 1] !== 'docx' && (
                                <main className="page" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                    <img src={file.link} alt="anexo" />
                                </main>
                            )}
                            {!generating && (
                                <div className="relative">
                                    <div className="w-full h-1 bg-[#E3E3E3]"></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className="buttons-relatory">
                <button onClick={handleCancelPdf} className="button-relatory">cancelar</button>
                <button onClick={id ? handleEditRelatory : handleGenerateRelatory} className="button-relatory">salvar</button>
            </div>
            <div className="container-relatory" style={{ position: "relative", display: "flex", justifyContent: "center", height: "40px", marginTop: "30px" }}>
                <p>© NPCA</p>
            </div>
        </div>
    );
}

export default Relatory;