import React, { useState } from 'react';
import ReportsEdit from './ReportsEdit';
import jsPDF from 'jspdf';

interface User {
  title: string,
  name: string,
  email: string,
  profileType: string,
  company: string,
  endereco1: string,
  endereco2: string,
  phone: string,
  date: string,
  image: string
}

const ReportsView = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState<User>({
    title: 'Título',
    company: 'Empresa XYZ',
    email: 'empresa.xyz@example.com',
    date: '00/00/0000',
    profileType: 'Cliente',
    endereco1: 'Rua ABC, 123 - Cidade, Estado',
    endereco2: 'Rua ABC, 123 - Cidade, Estado',
    phone: '(00) 1234-5678',
    name: 'nome',
    image: ''
  });
  const [image, setImage] = useState(null);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = (updatedUser: User) => {
    setUser(updatedUser);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const result = e.target.result;
      setImage(result || null);
    };
    reader.readAsDataURL(file);
  };

  const handleDownloadClick = () => {
    const doc = new jsPDF();

    // Título e imagem
    doc.text(`Relatório ${user.title}`, 10, 10);
    if (image) {
      doc.addImage(image, 'JPEG', 10, 20, 180, 160);
    }

    // Adicionar nova página
    doc.addPage();

    // Detalhes do relatório
    doc.text(`Detalhes do Relatório ${user.title}`, 10, 10);
    doc.text(`Nome: ${user.name}`, 10, 20);
    doc.text(`Empresa: ${user.company}`, 10, 30);
    doc.text(`Email: ${user.email}`, 10, 40);
    doc.text(`Tipo de Perfil: ${user.profileType}`, 10, 50);
    doc.text(`Data: ${user.date}`, 10, 60);
    doc.text(`Selo: ${user.name}`, 10, 70);
    doc.text(`Etapas: ${user.name}`, 10, 80);
    doc.text(`Categoria Específica: ${user.name}`, 10, 90);
    doc.text(`Auditor Principal: ${user.name}`, 10, 100);
    doc.text(`Auditor Validação: ${user.name}`, 10, 110);
    doc.text(`Assistente: ${user.name}`, 10, 120);
    doc.text(`Outras Informações: ${user.name}`, 10, 130);
    doc.text(`verificar requisitos de avaliação`, 10, 140);

    doc.save(`${user.title}_relatorio.pdf`);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 p-4 md:flex-row md:items-start animate-fade-left">
      <div className="mt-20 md:relative md:flex md:flex-col md:gap-4 md:top-auto md:right-auto md:mr-4">
        <button
          className="mb-4 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
          onClick={handleEditClick}
        >
          Editar
        </button>
        <button
          className="border border-gray-500 text-gray-700 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
          onClick={handleDownloadClick}
        >
          Download
        </button>
      </div>

      <div className="mt-20 relative flex flex-col bg-white shadow-md p-6 w-full md:w-[21cm] h-auto md:h-[29.7cm] overflow-y-auto">
        {isEditing ? (
          <ReportsEdit user={user} onSave={handleSave} onCancel={handleCancel} />
        ) : (
          <>
            <h1 className="text-2xl font-bold mb-4">Relatório {user.title}</h1>
            <input type="file" onChange={handleImageChange} className="mb-6" />
            {image && <img src={image} alt="Selecionada" className="mb-6 w-full h-auto" />}
            <p className="mb-6">Detalhes do Relatório {user.title}.</p>
            <hr></hr>
            <div className="border-transparent bg-white inline-block mb-4">
              <h2 className="text-lg font-bold mb-2">Informações do Cliente</h2>
              <p><strong>Nome:</strong> {user.name}</p>
              <p><strong>Empresa:</strong> {user.company}</p>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Tipo de Perfil:</strong> {user.profileType}</p>
              <p><strong>Data:</strong> {user.date}</p>
            </div>
            <hr />
            <div className="border-transparent bg-white inline-block mb-4 mt-5">
              <h2 className="text-lg font-bold mb-2">Selo</h2>
              <p><strong>Selo:</strong> {user.name}</p>
              <p><strong>Etapas:</strong> {user.name}</p>
              <p><strong>Categoria Específica:</strong> {user.name}</p>
            </div>
            <hr />
            <div className="border-transparent bg-white inline-block mb-4 mt-5">
              <h2 className="text-lg font-bold mb-2">Equipe de Auditoria</h2>
              <p><strong>Auditor Principal:</strong> {user.name}</p>
              <p><strong>Auditor Validação:</strong> {user.name}</p>
              <p><strong>Assistente:</strong> {user.name}</p>
            </div>
            <hr />
            <div className="border-transparent bg-white inline-block mb-4 mt-5">
              <h2 className="text-lg font-bold mb-2">Tópicos</h2>
              <p><strong>Outras Informações:</strong> {user.name}</p>
              <p>verificar requisitos de avaliação</p>
            </div>
            <hr />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportsView;
