import React, { useEffect, useState } from 'react';
import { projects } from '../api/spreadsheets';

const ConsultFE = () => {
  const [projectsData, setProjectsData] = useState([
    { id: 1, name: '', stakeholder_name: '', created_at: '' },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const projectsDataRequest = await projects();
      setProjectsData(projectsDataRequest);
    };

    fetchData();
  }, []);

  function formatDate(date: string) {
    const dateData = new Date(date);

    return `${parseInt(String(dateData.getDay())) < 10 ? '0'+String(dateData.getDay()) : dateData.getDay()}/${parseInt(String(dateData.getMonth())) < 10 ? '0'+String(dateData.getMonth()+1) : dateData.getMonth()+1}/${dateData.getFullYear()}`;
  }

  const handleViewProject = (id: number) => {
    window.location.href = `/spreadsheet/${id}`;
  };

  return (
    <div className="mt-20 p-4 md:p-8 animate-fade-left">
      <h1 className="text-2xl font-bold mb-4 text-center">Avaliações de Campo</h1>
      <p className="mb-6 text-center">Lista de Avaliações cadastradas no sistema.</p>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-300">Título</th>
              <th className="py-2 px-4 border-b border-gray-300">Cliente</th>
              <th className="py-2 px-4 border-b border-gray-300">Data</th>
              <th className="py-2 px-4 border-b border-gray-300">Ações</th>
            </tr>
          </thead>
          <tbody>
            {projectsData.map((project) => (
              <tr key={project.id} className="text-center">
                <td className="py-2 px-4 border-b border-gray-300">{project.name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{project.stakeholder_name}</td>
                <td className="py-2 px-4 border-b border-gray-300">{formatDate(project.created_at)}</td>
                <td className="py-2 px-4 border-b border-gray-300">
                  <button 
                    className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded-full hover:bg-[#005522] transition duration-300"
                    onClick={() => handleViewProject(project.id)}
                  >
                    Visualizar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ConsultFE;
