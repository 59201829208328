// src/pages/NewFE.js
import React, { useState } from 'react';

const NewFE = () => {
  const [formData, setFormData] = useState({
    campo1: '',
    campo2: '',
    campo3: '',
    // adicionar e renomear campos
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Adicione a lógica para enviar os dados do formulário
    console.log(formData);
  };

  return (
    <div className="container mx-auto p-4 animate-fade-left">
      <h1 className="text-2xl mb-4">Cadastrar Nova Ficha de Avaliação de Campo</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campo1">
            Título 
          </label>
          <input
            type="text"
            id="campo1"
            name="campo1"
            value={formData.campo1}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campo2">
            Cliente
          </label>
          <input
            type="text"
            id="campo2"
            name="campo2"
            value={formData.campo2}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campo3">
            Auditor
          </label>
          <input
            type="text"
            id="campo3"
            name="campo3"
            value={formData.campo3}
            onChange={handleChange}
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        {/* mais campos conforme necessário */}
        <button
          type="submit"
          className="bg-[#006633] hover:bg-[#005522] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cadastrar
        </button>
      </form>
    </div>
  );
};

export default NewFE;
