import React, { useEffect, useState, MouseEvent } from 'react';
import Header from '../components/Header'; 
import { categories, stages } from '../api/categoriesStages';
import { stamps } from '../api/stamps';
import FileUploadArea from '../components/FileUploadArea/fileUploadArea';
import axios from 'axios';
import MessageRequest from '../components/CardMessage/MessageRequest/messageRequest';
import { registerUserLogs } from '../api/userLogs';

interface Stamp {
  id: number
  stamp: string
  categories: number[]
  image: {
    link: string
    name: string
  }
}

interface Category {
  id: number
  categorie: string
  image: {
    link: string
    name: string
  }
  is_active: boolean
}

interface Stage {
  id: number
  stage: string
  image: {
    link: string
    name: string
  }
}

const Steps = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [errorImage, setErrorImage] = useState<string | null>(null);
  const [errorCategory, setErrorCategory] = useState<string | null>(null);
  const [currentSection, setCurrentSection] = useState('');
  const [nameFile, setNameFile] = useState<string>('');
  const [fileUpload, setFileUpload] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
  const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
  const [messageRequest, setMessageRequest] = useState<string>('');
  const [stampData, setStampData] = useState([{
    id: 0,
    stamp: '',
    categories: [],
    image: {
      link: '',
      name: ''
    }
  }]);
  const [categoryData, setCategoryData] = useState([{
    id: 0,
    categorie: '',
    image: {
      link: '',
      name: ''
    },
    is_active: false
  }]);
  const [stageData, setStageData] = useState([{
    id: 0,
    stage: '',
    image: {
      link: '',
      name: ''
    }
  }]);

  useEffect(() => {
      const fetchData = async () => {
          const responseStages = await stages();
          setStageData(responseStages);

          const responseCategories = await categories();
          setCategoryData(responseCategories);

          const responseStamps = await stamps();
          setStampData(responseStamps);
      }

      fetchData();
      
  }, []);

  const toggleModal = (section = '') => {
    setCurrentSection(section);
    setIsModalOpen(!isModalOpen);
  };

  const handleFileSelect = (file: any) => {
      setNameFile(file.name);
      setFileUpload(file);
  };

  const handleCheckboxChange = (categoryId: number) => {
      if (selectedCategories.includes(categoryId)) {
          setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
      } else {
          setSelectedCategories([...selectedCategories, categoryId]);
      }
  };

  const handleCreateCategory = async (event: React.MouseEvent<HTMLButtonElement>, section: string) => {
      event.preventDefault();

      const formData = new FormData();

      if (newCategory.trim() === '') {
        setErrorCategory('Campo obrigatório.');
        return;
      } else {
        setErrorCategory('');
      }

      if (fileUpload !== null) {
        formData.append('image', fileUpload);
        setErrorImage('');
      } else {
        setErrorImage('Campo obrigatório.');
        return;
      }

      if (section === 'Selo') {
        formData.append('stamp', newCategory);
        formData.append('categories', JSON.stringify(selectedCategories));

        axios.put(process.env.REACT_APP_URL_API_AMZ+'api/stamps/', formData, 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        .then((response) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Cadatrado com sucesso.')
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            registerUserLogs("Cadastrou selo(s).");
        })
        .catch((error) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Cadastro inválido. Revise os dados.')
        });
      }

      if (section === 'Categoria') {
        formData.append('categorie', newCategory);

        axios.put(process.env.REACT_APP_URL_API_AMZ+'api/categories/', formData, 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        .then((response) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Cadatrado com sucesso.')
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            registerUserLogs("Cadastrou categoria(s).");
        })
        .catch((error) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Cadastro inválido. Revise os dados.')
        });
      }

      if (section === 'Etapa') {
        formData.append('stage', newCategory);

        axios.post(process.env.REACT_APP_URL_API_AMZ+'api/stages/', formData, 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })
        .then((response) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Sucesso:');
            setMessageRequest('Cadatrado com sucesso.')
            setTimeout(() => {
                window.location.reload();
            }, 3000);
            registerUserLogs("Cadastrou etapa(s).");
        })
        .catch((error) => {
            setActiveMessageRequest(false);
            setActiveMessageRequest(true);
            setTitleMessageRequest('Erro:');
            setMessageRequest('Cadastro inválido. Revise os dados.')
        });
      }

      setNewCategory('');
      setSelectedCategories([]);
      setFileUpload(null);
      setNameFile('');
      toggleModal();
  };

  return (
    <div className="flex mt-12">
      <div className="flex-1">
        <Header />
        <div className="p-6 animate-fade-left">
          <div className="text-center relative min-h-screen">
            <h1 className="text-2xl font-bold mb-4">Selos</h1>
            <p className="mb-6">Conteúdo</p>
            <div className="flex flex-wrap justify-center gap-4">
              {stampData?.map((data: Stamp) => (
                <a key={data.id} href={"/tabela-selos/"+data.id} rel="noopener noreferrer">
                  <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                    {data.stamp}
                  </button>
                </a>
              ))}
            </div>
            <button 
              className="mt-5 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
              onClick={() => toggleModal('Selo')}
            >
              Adicionar Selo
            </button>

            <h1 className="text-2xl font-bold mb-4 mt-10">Categorias Específicas</h1>
            <p className="mb-6">Conteúdo</p>
            <div className="flex flex-wrap justify-center gap-4">
              {categoryData?.map((data: Category) => (
                <a key={data.id} href={"/tabela-categorias/"+data.id} rel="noopener noreferrer">
                  <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                    {data.categorie}
                  </button>
                </a>
              ))}
            </div>
            <button 
              className="mt-5 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
              onClick={() => toggleModal('Categoria')}
            >
              Adicionar Categoria
            </button>

            <h1 className="text-2xl font-bold mb-4 mt-10">Etapas</h1>
            <p className="mb-6">Conteúdo</p>
            <div className="flex flex-wrap justify-center gap-4">
              {stageData?.map((data: Stage) => (
                <a key={data.id} href={"/tabela-etapas/"+data.id} rel="noopener noreferrer">
                  <button className="border border-gray-300 text-green-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300">
                    {data.stage}
                  </button>
                </a>
              ))}
            </div>
            <button 
              className="mt-5 border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
              onClick={() => toggleModal('Etapa')}
            >
              Adicionar Etapa
            </button>

            <MessageRequest activeProps={activeMessageRequest} title={titleMessageRequest} message={messageRequest} />

            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center animate-fade-up">
                <div className="bg-white p-6 rounded shadow-lg w-1/3">
                  <h2 className="text-xl font-bold mb-4">Adicionar {currentSection}</h2>
                  <div className="mb-4 flex flex-col items-start">
                    <label className="block text-sm font-medium mb-1">Logo *</label>
                    <FileUploadArea onFileSelect={handleFileSelect} nameFile={nameFile} marginTop={'20px'}/>
                    <span>{errorImage}</span>
                  </div>
                  <div className="mb-4 flex flex-col items-start">
                    <label className="block text-sm font-medium mb-1">Nome *</label>
                    <input
                      type="text"
                      name="newCategory"
                      value={newCategory}
                      onChange={(e) => setNewCategory(e.target.value)}
                      className={errorCategory && errorCategory !== '' ? "w-full px-4 py-2 border rounded-md focus:outline-none border-red-500" : "w-full px-3 py-2 border rounded" } 
                    />
                    <span>{errorCategory}</span>
                  </div>
                  {currentSection === 'Selo' && (
                    <div className="mb-4 flex flex-col items-start">
                    <label className="block text-sm font-medium mb-1">Categorias Específicas</label>
                    <ul>
                        {categoryData.map(category => (
                          <li key={category.id}>
                              <label style={{"fontSize": '12px', 'display': 'flex', 'alignItems': 'center'}}>
                                  <input
                                      type="checkbox"
                                      checked={selectedCategories.includes(category.id)}
                                      onChange={() => handleCheckboxChange(category.id)}
                                      className='mr-2'
                                  />
                                  {category.categorie}
                              </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                  )}
                  <div className="flex justify-end gap-4">
                    <button 
                      className="border border-gray-300 text-red-800 bg-white px-4 py-2 rounded hover:bg-gray-100 transition duration-300"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancelar
                    </button>
                    <button 
                      className="border border-gray-300 text-white bg-[#006633] px-4 py-2 rounded hover:bg-[#005522] transition duration-300"
                      onClick={(event) => handleCreateCategory(event, currentSection)}
                    >
                      Criar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
