import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export async function profile(url: string) {

    try {
        const response: any = await axios.get(process.env.REACT_APP_URL_API_AMZ+`api/auth-amz/${url}/`, 
        {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            }
        })

        if (response.status === 200) {
            if (url !== 'admin-amz') {
                localStorage.setItem('id', response.data['cliente']['id'])
            }
            return response.data;
        }
    } catch (error) {
        localStorage.clear();
        window.location.href = "/login";     
    }
}