import './viewStage.css'
// import Loading from '../..components/Loading/loading';
import Navbar from '../../components/Navbar/navbar';
import MessageRequest from '../../components/CardMessage/MessageRequest/messageRequest';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import ModalEditStage from '../../components/ModalEditStage/modalEditStage';
import { indicatorsFiltred } from '../../api/indicators';
import { categorieFiltred } from '../../api/categorieFiltred';
import { stampsFiltred } from '../../api/stamps';

export default function ViewStage() {
    const { name_table, id } = useParams();
    const [indicators, setIndicators] = useState<any>([]);
    const [stageSelected, setStageSelected] = useState<any>([]);
    const [namePage, setNamepage] = useState<string>('');
    const [nameNewButton, setNameNewButton] = useState<string>('');
    const [titleModal, setTitleModal] = useState<string>('');
    const [titleModalEdit, setTitleModalEdit] = useState<string>('');
    const [titleModalView, setTitleModalView] = useState<string>('');
    const [activeMessageRequest, setActiveMessageRequest] = useState<boolean>(false);
    const [titleMessageRequest, setTitleMessageRequest] = useState<string>('');
    const [messageRequest, setMessageRequest] = useState<string>('');
    const [activeLoading, setActiveLoading] = useState<boolean>(true)
    const [activeModalEdit, setActiveModalEdit] = useState<boolean>(false);
    const [activeModalView, setActiveModalView] = useState<boolean>(false);

    const idParams = id ? parseInt(id) : 0;

    useEffect(() => {
        const fetchData = async () => {
            if (name_table === 'tabela-etapas') {
                try {
                    const indicatorsRequest = await indicatorsFiltred(idParams)
                    
                    setIndicators(indicatorsRequest);
                    if (indicators !== null || indicators !== '') {
                        setActiveLoading(false)
                    } 

                } catch (error) {
                    console.error('Erro ao buscar perfil:', error);
                }
            } else if (name_table === 'tabela-categorias') {
                try {
                    const categorieRequest = await categorieFiltred([idParams])
                    
                    setIndicators(categorieRequest);
                    if (indicators !== null || indicators !== '') {
                        setActiveLoading(false)
                    }  
                } catch (error) {
                    console.error('Erro ao buscar perfil:', error);
                }
            } else if (name_table === 'tabela-selos') {
                try {
                    const stampRequest = await stampsFiltred(idParams)
                    let listIndicators = []
                    for (let i = 0; i < stampRequest.length; i++) {
                        let requestIndicators = await categorieFiltred([stampRequest[i].id])
                        for (let j = 0; j < requestIndicators.length; j++) {
                            for (let categorie of requestIndicators[j].categories) {
                                if (categorie === stampRequest[i].id) {
                                    listIndicators.push({
                                        id: stampRequest[i].id,
                                        categorie: stampRequest[i].categorie,
                                        image: stampRequest[i].image,
                                        is_active: stampRequest[i].is_active,
                                        stage: requestIndicators[j].stage_id_stage,
                                        requirements: requestIndicators[j].requirements,
                                        indicators: requestIndicators[j].indicators
                                    })
                                }
                            }
                        }
                    }

                    setIndicators(listIndicators);
                    if (indicators !== null || indicators !== '') {
                        setActiveLoading(false)
                    } 
                } catch (error) {
                    console.error('Erro ao buscar perfil:', error);
                }
            }
        };

        fetchData();
    }, [indicators.length !== 0]);

    const handleNextScreen = (seleted: any) => {
        setStageSelected(seleted);
        setActiveModalEdit(!activeModalEdit);
    }

    const handleViewNextScreen = (seleted: any) => {
        window.location.href = `/indicador/${name_table}/${seleted['id']}`
    }

    useEffect(() => {
        if (name_table === 'tabela-etapas') {
            setNamepage('Etapas');
            setNameNewButton('+ novo indicador');
            setTitleModal('Novo Indicador');
            setTitleModalEdit('Editar Indicador')
            setTitleModalView('Indicador')
        } else if (name_table === 'tabela-categorias') {
            setNamepage('Categorias Específicas');
            setNameNewButton('+ nova etapa');
            setTitleModal('Nova Etapa');
            setTitleModalEdit('Editar Etapa')
            setTitleModalView('Etapa')
        } else if (name_table === 'tabela-selos') {
            setNamepage('Selos');
            setNameNewButton('+ nova categoria');
            setTitleModal('Nova Categoria');
            setTitleModalEdit('Editar Categoria')
            setTitleModalView('Categoria')
        }
    }, [namePage, nameNewButton, titleModal])

    const backScreen = () => {
        window.location.href = '/home-page';
    };

    return (
        <div className='view-stages'>
            <ModalEditStage activeModal={activeModalEdit} titleModal={titleModalEdit} indicator={stageSelected} open={(open: any) => setActiveModalEdit(open)} />
            <Navbar title="Sistema AMZ" user={true} search={false} button={name_table === 'tabela-selos' ? false : true} nameButton={nameNewButton} titleModal={titleModal} indicators={indicators} idStage={idParams} buttonImport={true} idCategorie={idParams} back={backScreen} />
            <section className='section-view-stages animate-fade-left'>
                <div className='col-title-image-view-stages'>
                    <img
                        src="/amz.png"
                        alt="Imagem de cadastro"
                        className="register-image"
                    />
                    <h2>{namePage}</h2>
                    <hr className='divider-title' style={{ 'width': '90%' }} />
                </div>
                {/* <MessageRequest active={activeMessageRequest} title={titleMessageRequest} message={messageRequest}/> */}
                {name_table !== 'tabela-selos' ? 
                    (<div className='row-view-stages' style={{'paddingBottom': 0}}>
                        <h3 style={{'width': '140px'}} className='title-id-categorie'>
                            etapa
                        </h3>
                        <h3 style={{'width': '250px'}} className='title-requirement-name'>
                            requisito
                        </h3>
                        <h3 style={{'width': '80px'}} className='title-cod-name'>
                            cod
                        </h3>
                        <h3 style={{'width': '280px'}}>
                            indicador
                        </h3>
                        <h3 style={{width: '80px'}}>
                            ações
                        </h3>
                    </div>) : 
                    (<div className='row-view-stages' style={{'paddingBottom': 0}}>
                        <h3 style={{'width': '60px', 'textAlign': 'left'}} className='title-id-categorie'>
                            id
                        </h3>
                        <h3 style={{'width': '200px', 'textAlign': 'left'}} className='requerements-categorie'>
                            categoria
                        </h3>
                        <h3 style={{'width': '200px', 'textAlign': 'left'}}>
                            etapa
                        </h3>
                        <h3 style={{'width': '300px', 'textAlign': 'left'}} className='title-requirement-categorie'>
                            requisito
                        </h3>
                        <h3 style={{'width': '250px', 'textAlign': 'left'}}>
                            indicador
                        </h3>
                        <h3 style={{'width': 'auto', 'marginLeft': '40px'}} className='title-actions'>
                            ações
                        </h3>
                    </div>)
                }
                <hr className='divider-stage' style={{'marginBottom': 0, 'marginTop': '10px'}} />
                {name_table !== 'tabela-selos' ?
                    (indicators.length !== 0 ? indicators.map((indicator: any) => (
                        <div className='col-users'>
                            <div className='row-view-stages'>
                                <p key={indicator.id} style={{'width': '140px'}} className='stage-name'>
                                    {indicator.stage_id_stage}
                                </p>
                                <p key={indicator.id} style={{'width': '250px'}} className='requerements-categorie'>
                                    {indicator.requirements.length <= 65 ? indicator.requirements : indicator.requirements.substring(0, 65)+'...'}
                                </p>
                                <p key={indicator.id} style={{'width': '80px'}}>
                                    {indicator.cod}
                                </p>
                                <p key={indicator.id} style={{'width': '280px'}}>
                                    {indicator.indicators.length <= 65 ? indicator.indicators : indicator.indicators.substring(0, 65)+'...'}
                                </p>
                                <div className='row-buttons-action'>
                                    <button className='button-view-stage'>
                                        <img src="/svg_edit.svg" alt="svg_edit" className='img-icon-view' style={{width: "20px"}} onClick={() => handleNextScreen(indicator)} />
                                    </button>
                                    <button className='button-view-stage'>
                                        <img src="/olho_negro.svg" alt="olho_negro" className='img-icon-view' style={{width: "20px"}} onClick={() => handleViewNextScreen(indicator)} />
                                    </button>
                                </div>
                            </div>
                            <hr className='divider-stage' style={{'marginBottom': 0}} />
                        </div>
                    ))
                    : <div className="div-data-null">
                        <p>Não há dados cadastrados</p>
                    </div>) :
                    (indicators.length !== 0 ? indicators.map((indicator: any) => (
                        <div className='col-users'>
                            <div className='row-view-stamps'>
                                <p key={indicator.id} className='title-id-categorie'>
                                    {indicator.id}
                                </p>
                                <p key={indicator.id} style={{'width': '150px', 'textAlign': 'left'}} className='requerements-categorie'>
                                    {indicator.categorie}
                                </p>
                                <p key={indicator.id} style={{'width': '150px', 'textAlign': 'left'}}>
                                    {indicator.stage}
                                </p>
                                <p key={indicator.id} style={{'width': '250px', 'textAlign': 'left'}} className='requerements-categorie' id='requirements-id'>
                                    {indicator.requirements.length <= 65 ? indicator.requirements : indicator.requirements.substring(0, 65)+'...'}
                                </p>
                                <p key={indicator.id} style={{'width': '250px', 'textAlign': 'left'}}>
                                    {indicator.indicators.length <= 65 ? indicator.indicators : indicator.indicators.substring(0, 65)+'...'}
                                </p>
                                <button className='button-view-stage'>
                                    <img src="/svg_edit.svg" alt="svg_edit" className='img-icon-view' style={{width: "20px"}} onClick={() => handleNextScreen(indicator)} />
                                </button>
                            </div>
                            <hr className='divider-stage' style={{'marginBottom': 0}} />
                        </div>
                    ))
                    : <div className="div-data-null">
                        <p>Não há dados cadastrados</p>
                    </div>)
                }
            </section>
        </div>
    );
}